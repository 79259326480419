import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { PatientDto, UpsertPatientExercisingDto, UpsertPatientTestingDto } from 'ts-types/api.types';

const PatientData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 0.5rem;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.15rem;
  color: var(--light-blue);

  @media only screen and (max-width: 992px) {
    //justify-content: flex-start;
    padding-right: 0;
    float: left;
  }
`;

interface Props extends LoaderProps {
  patient?: PatientDto,
  patientTesting?: Partial<UpsertPatientTestingDto>
  patientExercising?: UpsertPatientExercisingDto
}

const PatientInfoRenderComponent = (props: Props) => {

  const isIphone = useIsIpadWidthOrBelow(430);
  const { patient, patientTesting } = props;
  const { t } = useTranslation('teresa');

  const patientInfo = (patient: PatientDto | undefined, patientTesting: Partial<UpsertPatientTestingDto> | undefined) => {

    const patientAge = patientTesting?.patientAge;
    let ageString = <span>{t('testresult.age')}:&nbsp; {patientAge}</span>;

    if (patientTesting?.patientAge) {
      ageString = <span>{t('testresult.age')}:&nbsp; {patientTesting?.patientAge}</span>;
    }

    let schoolYearsString = <span>{""}</span>;
    let schoolYears = patientTesting?.patientSchoolYears ? patientTesting.patientSchoolYears
      :  patient?.schoolYears ? patient.schoolYears : -1;

    if(schoolYears > -1) {
      schoolYearsString =
        <span>{isIphone ? t('patient.schoolYearsShort') : t('patient.schoolYears')}:&nbsp; {schoolYears}</span>
    }

    return (
      <>
        <span>{isIphone ? t('patient.patientIdShort') : t('patient.patientId') }:&nbsp; {patient?.originId}</span>
        <span>{t('patient.gender')}:&nbsp; {t(`gender.${patient?.gender}`)}</span>
        {patientTesting ? ageString : ""}
        {schoolYearsString}
      </>
    );

  };

  return (
    <PatientData>
      {patient || patientTesting ? patientInfo(patient, patientTesting) : ''}
    </PatientData>
  );
};

export default PatientInfoRenderComponent;
