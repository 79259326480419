import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ScopeType } from 'ts-types/api.enums';
import { QuestionSetExtendedSearchResponseDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/teresa-conf/questionset/extended-search`;

export const getExtendedSearchQuestionSets = (scopeType: ScopeType, source: CancelTokenSource): Promise<QuestionSetExtendedSearchResponseDto> => {
  return axios
  .get(`${apiUri}`, { cancelToken: source.token, params: {scopeType} })
  .then(response => response.data);
};

export const getExtendedSearchQuestionSetsForDoctor = (scopeType: ScopeType, source: CancelTokenSource): Promise<QuestionSetExtendedSearchResponseDto> => {
  return axios
  .get(`${apiUri}/for-doctor`, { cancelToken: source.token, params: {scopeType} })
  .then(response => response.data);
};