import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import PatientInfoHeaderComponent from 'components/final-form/PatientInfoHeaderComponent';
import RadioGroup from 'components/final-form/RadioGroup';
import TextArea from 'components/final-form/TextArea';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import { usePatientExerciseFormDataContext } from 'context/PatientExerciseFormDataContext';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-calculate';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Simulate } from 'react-dom/test-utils';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { StyledDataEntry, StyledLabel } from 'routes/patienttesting/AnswerQuestionsView';
import { Accordion, Grid, Icon, List, ListItem, RadioProps } from 'semantic-ui-react';
import { getExerciseResults } from 'service/teresaConfService';
import { getPatientExercising, saveExercising } from 'service/patientExercisingService';
import styled from 'styled-components';
import { UnitType } from 'ts-types/api.enums';
import {
  ExerciseResultDto,
  ExercisingQuestionAnswerPairsDto,
  ExercisingRequest,
  ObjectValidationErrorDto,
  ParamWithValueDto,
  PatientExerciseResultDto,
  PossibleAnswersDto,
  QuestionAnswerPairDto,
  QuestionDto,
  UpsertExercisingDto,
  UpsertPatientExercisingDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { ArrayDirection, findPrevOrNextElement, useAfterFirstRender } from 'util/functionUtils';
import { getMinMaxDescriptionTitle } from 'util/stringUtils';
import {
  composeValidators,
  isValueValidNumber,
  minMaxValueValidator,
  mustBeNumber,
  required,
} from 'util/validatorUtils';

const ParamContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const ParamsRow = styled(Grid.Row)`
  display: flex;
  max-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 5px;
  margin-bottom: 0.5rem;

  .column:first-child {
    flex-grow: 1;
  }
`;

const ParamsColumn = styled(Grid.Column)`
  display: flex;
  font-size: 15px;
  max-height: 100%;
  padding-top: 10px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;

  .timer-column {
    padding-left: 0 !important;
  }

  input {
    max-height: 35px;
    text-align: right !important;
  }
`;

const ParamsDiv = styled.div`
  padding-left: 1rem !important;
  margin-top: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExerciseParamsDiv = styled.div`
  font-weight: 600;
  padding-top: 15px;
  padding-left: 1rem;
`;

const StyledTabs = styled.div`
  display: flex;
  overflow: hidden;

  .tab {
    cursor: pointer;
    padding: 5px 20px 10px;
    color: var(--dark-gray);
    margin-top: 10px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: 200ms all ease-in-out;
  }

  .tab.selected {
    border-bottom-color: var(--primary-color);
  }
`;

const QualitativeComment = styled(Grid.Row)`
  display: flex;
  background-color: var(--very-light-gray);
  border-radius: 5px;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  margin-bottom: 0.5rem;
`;

const AccordionTitle = styled(Grid.Column) `
  display: flex !important;
  align-items: baseline !important;
`;

const ScrollableTsaGrid = styled(TsaGrid)`
  position: fixed;
  top: 115px;
  bottom: 75px;
  overflow: auto;
`;

const FormButtonsWrapper = styled(TsaGrid)`
  position: fixed;
  bottom: 0;
  width: calc(100% - 255px);
  height: 60px;

  & .buttons-container {
    padding-top: 20px;
    border-top: 1px solid var(--light-slate-blue);
  }
`;

const cancelTokenSource = axios.CancelToken.source();
const extractValueFromList = (value: Array<string>) => value && value.length === 1 ? value[0] : '';

const generateRadioButtonOptions = (
  ids: Array<string | number>,
  labels: Array<string>,
  groupName: string,
  t: Function,
  values?: Array<string | number>): Array<RadioProps> => {

  return labels.map((label, index) => ({
      id: ids[index],
      label: t(`${label}`),
      name: groupName,
      value: values ? values[index] : index,
    }
  ));
};

const getAnswerDescriptionMap = (answer: PossibleAnswersDto): { [key: string]: string } => {
  return {
    'de': answer.description,
    'en': answer.descriptionEn || answer.description,
    'fr': answer.descriptionFr || answer.description,
    'it': answer.descriptionIt || answer.description,
  };
};

const ExercisingForm = () => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { state } = useLocation();
  const { language } = useAuthContext();
  const isIpad = useIsIpadWidthOrBelow(1280);

  const {setClinicId} = useClinicHeaderContext();

  const {
    patientExerciseFormData,
    setPatientExerciseFormData,
    clearPatientExerciseFormData,
    initializePatientExerciseForm,
    patientData,
    exerciseResultParamMap,
    formDataLoaded,
  } = usePatientExerciseFormDataContext();

  const prevPath: string | undefined = state?.prevPath ? state?.prevPath : undefined;
  const patientExercisingId: number | undefined = state?.patientExercisingId ? Number(state?.patientExercisingId) : undefined;
  const [patientExercising, setPatientExercising] = useState<UpsertPatientExercisingDto>();
  const [exerciseConfResults, setExerciseConfResults] = useState<ExerciseResultDto[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [exerciseIx, setExerciseIx] = useState<number>(0);
  const [questionSetIx, setQuestionSetIx] = useState<number>(0);
  const [exerciseResultTabIndex, setExerciseResultTabIndex] = useState<number>(0);

  const [activeQuestionSet, toggleQuestionSetAccordion] = useState(true);
  const [activeExercise, toggleExerciseAccordion] = useState(false);

  const errorMessageRef = useRef(null);
  let errorElement = document.getElementById('errorElement');
  let errorElementHeight = errorElement != null ? errorElement.offsetHeight : 0;

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchPatientExercising();
  }, [patientExercisingId]);

  useEffect(() => {
    if (patientExerciseFormData
      && patientExerciseFormData.patientExerciseResultDtos
      && patientExerciseFormData.patientExerciseResultDtos[exerciseIx]
    ) {
      const exerciseConfId = patientExerciseFormData.patientExerciseResultDtos[exerciseIx].exerciseConfId;
      fetchExerciseResultData(exerciseConfId);
    }
  }, [exerciseIx, patientExerciseFormData?.patientExerciseResultDtos![exerciseIx]?.exerciseConfId]);

  useEffect(() => {
    const scrollableForm = document.getElementById('scrollable-form');
    if (errorMessageRef.current) {
      errorElement = document.getElementById('errorElement');
      errorElementHeight = errorElement != null ? errorElement.offsetHeight : 0;

      if (scrollableForm !== null) {
        scrollableForm.style.marginTop = isIpad
          ? `calc(${errorElementHeight}px + 20px)`
          : `${errorElementHeight}px`;
      }
    } else {
      if (scrollableForm !== null) {
        scrollableForm.style.marginTop = isIpad
          ? '20px'
          : '';
      }
    }
  }, [errorMessage]);

  const fetchExerciseResultData = async (exerciseConfId: number) => {
    try {
      const exerciseResults = await getExerciseResults(exerciseConfId, cancelTokenSource);
      setExerciseConfResults(exerciseResults);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const fetchPatientExercising = async (childPatientExercisingId?: number) => {
    if (patientExercisingId) {
      try {

        const request: Partial<ExercisingRequest> = {
          patientExercisingId: patientExercisingId,
          childPatientExercisingId: childPatientExercisingId
        };

        await initializePatientExerciseForm(request);
        const patientExercisingDto =
          await getPatientExercising(patientExercisingId, false, cancelTokenSource);

        setPatientExercising(patientExercisingDto);
        if (patientExercisingDto.questionSetDtos.length === 0
          && patientExercisingDto.exerciseConfDtoList.length > 0) {
          toggleQuestionSetAccordion(false);
          toggleExerciseAccordion(true)
        }
      } catch (e) {
        handleError(e.response.data);
      }
    }

  };

  const handleError = useCallback((error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.minMaxValueInvalidValues,
        errorUtils.invalidInput,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach((violation: ObjectValidationErrorDto) => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`, { minMaxValue: violation.details }));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  }, []);

  const goToPreviousPage = () => {
    if (prevPath === '/') {
      history.push(prevPath);
    } else if (prevPath === '/doctor/details') {
      history.push(prevPath!, { id: patientData?.treaterId, clinicId: state.clinicId });
    } else {
      history.push(prevPath!, { id: patientData!.id, clinicId: state.clinicId });
    }

    clearPatientExerciseFormData();
  };

  const goToEditExercising = () => {
    setTimeout(() => {
      history.push('/exercise-testing', {
        patientExercisingId: patientExercisingId,
        patientId: patientData!.id,
        exerciseTemplateId: patientExercising?.exerciseTemplateId,
        prevPath: prevPath,
        fromPatientExercising: true,
        clinicId: state.clinicId
      });
    }, 1200);
  };

  const previousNextHandler = async (direction: ArrayDirection, form: FormApi) => {
    const { hasValidationErrors } = form.getState();

    if (patientExerciseFormData && !hasValidationErrors) {
      const currentChildPatientExercisingId = patientExerciseFormData.patientExercisingId;

      const childPatientExercisingIds = patientExerciseFormData.childPatientExercisingIds;
      let childPatientExercisingId = undefined;
      if (childPatientExercisingIds) {
        childPatientExercisingId =
          findPrevOrNextElement(childPatientExercisingIds, currentChildPatientExercisingId, direction);
      }

      await fetchPatientExercising(childPatientExercisingId);
    } else if (hasValidationErrors) {
      setErrorMessage(t('error.invalidParam'));
    }
  };

  const resetExerciseIndexes = () => {
    setExerciseIx(0);
    setExerciseResultTabIndex(0);
  };

  const submitHandler = useCallback(async (isCompleted: boolean, errors: any, hasValidationErrors: boolean, exitPage: boolean) => {
    const patientExercisingId = patientExerciseFormData!.patientExercisingId!;
    if (isCompleted && !hasValidationErrors) {
      try {

        const request = {
          ...patientExerciseFormData!,
          completed: isCompleted,
        };

        await saveExercising(patientExercisingId, request, cancelTokenSource);
        if (exitPage) {
          goToPreviousPage();
        }
      } catch (e) {
        handleError(e.response.data);
      }
    } else if (hasValidationErrors) {
      toggleQuestionSetAccordion(errors.questionAnswerDtos !== undefined);
      toggleExerciseAccordion(errors.patientExerciseResultDtos !== undefined);
      setErrorMessage(t('error.invalidParam'));
    }
  }, [patientExerciseFormData]);

  const handleFormValuesChange = useAfterFirstRender(({ values }: any): void => {
    setPatientExerciseFormData(values);
  });

  const onTabClickHandler = async (index: number) => {
    await setExerciseResultTabIndex(index);
  };

  const exerciseResultTabsRenderer = (): React.ReactNode => {

    const tabs = exerciseConfResults.map((result, index) => {
      const defaultDescription = result.description;

      const texts: { [key: string]: string } = {
        'de': defaultDescription,
        'en': result.descriptionEn,
        'fr': result.descriptionFr,
        'it': result.descriptionIt,
      };

      let description = texts[language] || defaultDescription;

      return (
        <div key={result.id}
             className={`tab ${exerciseResultTabIndex === index && 'selected'}`}
             onClick={() => onTabClickHandler(index)}
        >
              <span title={description}>
                {description}
              </span>
        </div>
      );
    });

    return (
      <StyledTabs>
        {tabs}
      </StyledTabs>
    );
  };

  const exerciseTabsRenderer = (): React.ReactNode => {
    const tabs = patientExerciseFormData && patientExerciseFormData.patientExerciseResultDtos
      ? patientExerciseFormData.patientExerciseResultDtos.map((exercise, index) => {

        let description = `${index + 1}. ${translateExerciseDescription(exercise)}`;

        return (
          <div key={exercise.exerciseConfId}
               className={`tab ${exerciseIx === index && 'selected'}`}
               onClick={() => {
                 setExerciseIx(index);
                 setExerciseResultTabIndex(0);
               }}
          >
              <span title={description}>
                {description}
              </span>
          </div>
        );
      })
      : [];

    return (
      <StyledTabs>
        {tabs}
      </StyledTabs>
    );
  };

  const sumExerciseResultParam = useMemo(() => createDecorator({
    field: new RegExp('^patientExerciseResultDtos\\[' + exerciseIx + ']\\.exerciseResultWithParamDtos\\[' + exerciseResultTabIndex + ']\\.paramDtoList\\[.*]\\.resultNumber$'),
    updates: (value, name, allValues?: Partial<UpsertExercisingDto>) => {
      if (allValues) {
        let values = { ...allValues };
        const paramDtoPath = name.replace('.resultNumber', '');
        const paramId = _.get(values, paramDtoPath).paramId;
        const param = exerciseResultParamMap[paramId];

        if (param && UnitType.SUM !== param.unit) {
          const paramIds = _.map(values.patientExerciseResultDtos![exerciseIx].exerciseResultWithParamDtos![exerciseResultTabIndex].paramDtoList, 'paramId');
          const paramIx = paramIds.findIndex(p => exerciseResultParamMap[p] && UnitType.SUM === exerciseResultParamMap[p].unit);
          if (paramIx >= 0) {
            values.patientExerciseResultDtos![exerciseIx].exerciseResultWithParamDtos![exerciseResultTabIndex].paramDtoList[paramIx].resultNumber = 0;

            const sumValue = _.chain(values.patientExerciseResultDtos![exerciseIx].exerciseResultWithParamDtos![exerciseResultTabIndex].paramDtoList)
            .filter(paramDto => (paramDto.resultNumber !== null
                && paramDto.resultNumber !== undefined
                && isValueValidNumber(paramDto.resultNumber))
              || (exerciseResultParamMap[paramDto.paramId] && UnitType.SUM === exerciseResultParamMap[paramDto.paramId].unit))
            .map(paramDto => parseFloat(paramDto.resultNumber.toString()))
            .sum()
            .value();

            _.set(
              values,
              `patientExerciseResultDtos[${exerciseIx}].exerciseResultWithParamDtos[${exerciseResultTabIndex}].paramDtoList[${paramIx}].resultNumber`,
              sumValue === 0 ? '' : sumValue,
            );
          }
        }

        return {
          ...values,
        };
      }
      return {};
    },
  }), [exerciseIx, exerciseResultTabIndex]);

  const questionSetTabsRenderer = (): React.ReactNode => {
    const tabs = patientExerciseFormData && patientExerciseFormData.questionAnswerDtos
      ? patientExerciseFormData && patientExerciseFormData.questionAnswerDtos.map((questionSet, index) => {

      let description = `${index + 1}. ${translateQuestionSetName(questionSet)}`;

      return (
        <div key={questionSet.name}
             className={`tab ${questionSetIx === index && 'selected'}`}
             onClick={() => {
               setQuestionSetIx(index);
             }}
        >
              <span title={description}>
                {description}
              </span>
        </div>
      );
    })
      : [];

    return (
      <StyledTabs>
        {tabs}
      </StyledTabs>
    );
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={patientExerciseFormData}
        decorators={[sumExerciseResultParam]}
        subscription={{ pristine: true, submitting: true, values: true, errors: true, hasValidationErrors: true}}
        render={(formProps: FormRenderProps<Partial<UpsertExercisingDto>>) => renderFormContent(formProps)}
      />
    );
  };

  const translateExerciseDescription = (patientExerciseResultDto: PatientExerciseResultDto) => {
    const defaultDesc = patientExerciseResultDto.description;
    const texts: { [key: string]: string } = {
      'de': patientExerciseResultDto.description || defaultDesc,
      'en': patientExerciseResultDto.descriptionEn || defaultDesc,
      'fr': patientExerciseResultDto.descriptionFr || defaultDesc,
      'it': patientExerciseResultDto.descriptionIt || defaultDesc,
    };
    return texts[language];
  };

  const translateQuestionSetName = (exercisingQuestionSetDto: ExercisingQuestionAnswerPairsDto) => {
    const defaultName = exercisingQuestionSetDto.name;
    const texts: { [key: string]: string } = {
      'de': exercisingQuestionSetDto.name || defaultName,
      'en': exercisingQuestionSetDto.nameEn || defaultName,
      'fr': exercisingQuestionSetDto.nameFr || defaultName,
      'it': exercisingQuestionSetDto.nameIt || defaultName,
    };
    return texts[language];
  };

  const renderFormContent = (
    { handleSubmit, submitting, values, form, errors, hasValidationErrors}: FormRenderProps<any>): React.ReactNode => {

    const currentPatientExercise = values.patientExerciseResultDtos[exerciseIx];
    const questionSet = values.questionAnswerDtos && values.questionAnswerDtos[questionSetIx] ?
      values.questionAnswerDtos[questionSetIx].questionAnswerDtos : [];

    const disabledPrev = patientExerciseFormData
      && patientExerciseFormData.sessionNumber === 1;

    const disabledNext = patientExerciseFormData
      && patientExercising
      && ((patientExerciseFormData.sessionNumber === patientExercising.numberOfSessions)
        || (patientExerciseFormData.sessionNumber === patientExerciseFormData.childPatientExercisingIds?.length));

    let paramResultNums: any[] = [];
    patientExerciseFormData!.patientExerciseResultDtos!.forEach(per => {
      per.exerciseResultWithParamDtos.forEach(paramDto => {
        if (paramDto.paramDtoList) {
          paramDto.paramDtoList.forEach(p => {
            paramResultNums.push(p.resultNumber);
          });
        }
      });
    });

    let listLeft: QuestionAnswerPairDto[] = [];
    let listRight: QuestionAnswerPairDto[] = [];
    let numOfQuestionsLeft = 0;
    if (questionSet) {
      numOfQuestionsLeft = Math.round(questionSet.length / 2);
      questionSet.forEach((q: QuestionAnswerPairDto, index: number) => {
        if (questionSet.length <= 4) {
          listLeft.push(q);
        } else if (index < numOfQuestionsLeft) {
          listLeft.push(q);
        } else {
          listRight.push(q);
        }
      });
    }

    let renderExerciseTitle = "";

    if (patientExerciseFormData) {
      const patientExerciseDate = patientExerciseFormData.startDate
        ? moment(patientExerciseFormData.startDate).format('DD.MM.YYYY HH:mm')
        : undefined;

      renderExerciseTitle = patientExerciseDate
        ? "(" + patientExerciseDate + " - " + patientExerciseFormData.doctorName + ")"
        : "(" + patientExerciseFormData.doctorName + ")";
    }

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          {patientExercising && patientExerciseFormData
            ?
            <PatientInfoHeaderComponent
              titleElement={
              <div style={{display: 'inline-flex', alignItems: 'baseline'}}>
                <div className='title-h1' style={{marginBottom: 0}}>
                  {t('patientExercising.progress',
                    {
                      sessionNumber: patientExerciseFormData.sessionNumber,
                      numberOfSessions: patientExercising.numberOfSessions,
                    })}
                </div>
                <div style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  marginLeft: '20px'
                }}>
                  {renderExerciseTitle}
                </div>
              </div>
              }
              patient={patientData!}
              patientExercising={patientExercising}
            />
            : <></>
          }
          {errorMessage &&
            <Grid.Row>
              <Grid.Column>
                <div className='error' id='errorElement' ref={errorMessageRef}>
                  <StyledErrorMessage onDismiss={() => setErrorMessage(undefined)}>
                    <div>{errorMessage}</div>
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }
        </TsaGrid>
        <ScrollableTsaGrid
          id={"scrollable-form"}
          style={isIpad
            ? { bottom: '140px',
                top: '160px',
                width: 'calc(100% - 40px)'
            }
            : {}}
        >
          <Accordion fluid>
            {patientExercising && patientExercising.questionSetIds && patientExercising.questionSetIds.length > 0 &&
            <>
              <Accordion.Title
                active={activeQuestionSet}
                onClick={() => toggleQuestionSetAccordion(!activeQuestionSet)}
              >
                <Grid.Row>
                  <AccordionTitle width={16}>
                    <Icon name={activeQuestionSet ? 'angle down' : 'angle right'} size='large'/>
                    <div className='title-h1' style={{ marginBottom: '0' }}>
                      {t('questionAnswers.viewTitle')}
                    </div>
                  </AccordionTitle>
                </Grid.Row>
              </Accordion.Title>
              {
                questionSet &&
                <Accordion.Content active={activeQuestionSet}>
                  <Grid.Row>
                    <Grid.Column>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          {questionSetTabsRenderer()}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}
                                     style={{ borderTop: '1px solid #d4d9e0', margin: '1.5rem 0 1.5rem 0' }}
                        >
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column>
                          <Grid stackable doubling columns={2}>
                            <Grid.Column width={8}>
                              <InnerTsaGrid>
                                <Grid.Row>
                                  <Grid.Column width={16}>
                                    {listLeft.map((question: QuestionAnswerPairDto, qIx: number) => {
                                      if (question) {
                                        return (
                                          <div key={question.questionId}>
                                            {renderSingleQuestion(
                                              `questionAnswerDtos[${questionSetIx}].questionAnswerDtos[${qIx}]`,
                                              qIx,
                                              question,
                                              form,
                                            )}
                                          </div>
                                        );
                                      }
                                    })
                                    }
                                  </Grid.Column>
                                </Grid.Row>
                              </InnerTsaGrid>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <InnerTsaGrid>
                                <Grid.Row>
                                  <Grid.Column width={16}>
                                    {listRight.map((question, qIx) => {
                                      if (question) {
                                        return (
                                          <div key={question.questionId}>
                                            {renderSingleQuestion(
                                              `questionAnswerDtos[${questionSetIx}].questionAnswerDtos[${qIx + numOfQuestionsLeft}]`,
                                              qIx + numOfQuestionsLeft,
                                              question,
                                              form)}
                                          </div>
                                        );
                                      }
                                    })
                                    }
                                  </Grid.Column>
                                </Grid.Row>
                              </InnerTsaGrid>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Accordion.Content>
              }
              <Grid.Row>
                <Grid.Column width={16}
                             style={{ borderTop: '1px solid var(--very-light-blue)', margin: '2rem 0 2rem 0' }}
                >
                </Grid.Column>
              </Grid.Row>
            </>
            }
            <Accordion.Title
              active={activeExercise}
              onClick={() => toggleExerciseAccordion(!activeExercise)}
            >
              <Grid.Row>
                <AccordionTitle width={16}>
                  <Icon name={activeExercise ? 'angle down' : 'angle right'} size='large'/>
                  <div className='title-h1' style={{ marginBottom: '.5rem' }}>
                    {t('exerciseConf.viewTitle')}
                  </div>
                </AccordionTitle>
              </Grid.Row>
            </Accordion.Title>
            <Accordion.Content active={activeExercise}>
              <Grid.Row>
                <Grid.Column width={16}>
                  {exerciseTabsRenderer()}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}
                             style={{ borderTop: '1px solid #d4d9e0', margin: '1.5rem 0 0 0' }}
                >
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <InnerTsaGrid>
                    {currentPatientExercise
                    && currentPatientExercise.exerciseParams
                    && currentPatientExercise.exerciseParams.length
                    && exerciseResultParamMap
                      ? <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('exerciseConf.parameters')}</DataLabel>
                          <ParamContainer>
                            <InnerTsaGrid>
                              {currentPatientExercise.exerciseParams.map((paramWithValue: ParamWithValueDto, pIx: number) => {
                                const paramId = paramWithValue.paramId;
                                const minValue = exerciseResultParamMap[paramId].minimumValue;
                                const maxValue = exerciseResultParamMap[paramId].maximumValue;

                                let minMaxDescriptionTitle = t('value.mustBeNumber');
                                if (minValue !== null || maxValue !== null) {
                                  minMaxDescriptionTitle = getMinMaxDescriptionTitle(
                                    minValue,
                                    maxValue,
                                    t
                                  );
                                }

                                return (
                                  <ParamsRow key={paramId}>
                                    <ParamsColumn width={7}>
                                      <ParamsDiv>
                                          <span title={exerciseResultParamMap[paramId].description}>
                                            {exerciseResultParamMap[paramId].description}
                                          </span>
                                      </ParamsDiv>
                                    </ParamsColumn>
                                    <ParamsColumn width={3} className='padding-1rem' style={{ paddingTop: '7px' }}>
                                      <Field
                                        name={`patientExerciseResultDtos[${exerciseIx}].exerciseParams[${pIx}].resultNumber`}
                                        component={Input}
                                        title={minMaxDescriptionTitle}
                                        validate={composeValidators(
                                          mustBeNumber,
                                          minMaxValueValidator(
                                            minValue, maxValue
                                          )
                                        )}
                                      />
                                    </ParamsColumn>
                                    <ParamsColumn width={2}>
                                      <ParamsDiv>
                                          <span title={t(`unit.${exerciseResultParamMap[paramId].unit}`)}>
                                            {t(`unit.${exerciseResultParamMap[paramId].unit}`)}
                                          </span>
                                      </ParamsDiv>
                                    </ParamsColumn>

                                  </ParamsRow>
                                );
                              })
                              }
                            </InnerTsaGrid>
                          </ParamContainer>
                        </Grid.Column>
                      </Grid.Row>
                      : <></>
                    }

                    <Grid.Row>
                      <Grid.Column width={16}>
                        {exerciseResultTabsRenderer()}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <InnerTsaGrid>
                          <Grid.Row>
                            <Grid.Column width={16}>
                              <DataLabel>{t('patientExercising.params')}</DataLabel>
                              <ParamContainer>
                                <InnerTsaGrid>
                                  {currentPatientExercise
                                  && currentPatientExercise.exerciseResultWithParamDtos
                                  && currentPatientExercise.exerciseResultWithParamDtos.length
                                  && currentPatientExercise.exerciseResultWithParamDtos[exerciseResultTabIndex]
                                  && currentPatientExercise.exerciseResultWithParamDtos[exerciseResultTabIndex].paramDtoList
                                  && currentPatientExercise.exerciseResultWithParamDtos[exerciseResultTabIndex].paramDtoList.length
                                    ? currentPatientExercise.exerciseResultWithParamDtos[exerciseResultTabIndex].paramDtoList
                                    .map((paramValueWithDto: ParamWithValueDto, index: number) => {
                                      const param = exerciseResultParamMap[paramValueWithDto.paramId];
                                      const minValue = exerciseResultParamMap[paramValueWithDto.paramId].minimumValue;
                                      const maxValue = exerciseResultParamMap[paramValueWithDto.paramId].maximumValue;

                                      let minMaxDescriptionTitle = t('value.mustBeNumber');
                                      if (minValue !== null || maxValue !== null) {
                                        minMaxDescriptionTitle = getMinMaxDescriptionTitle(
                                          minValue,
                                          maxValue,
                                          t
                                        );
                                      }

                                      return (
                                        <ParamsRow key={paramValueWithDto.paramId}>
                                          <ParamsColumn width={7}>
                                            <ParamsDiv>
                                                <span title={param.description}>
                                                  {param.description}
                                                </span>
                                            </ParamsDiv>
                                          </ParamsColumn>
                                          <ParamsColumn width={3} className='padding-1rem'
                                                        style={{ paddingTop: '7px' }}>
                                            <Field
                                              name={
                                                `patientExerciseResultDtos[${exerciseIx}].exerciseResultWithParamDtos[${exerciseResultTabIndex}].paramDtoList[${index}].resultNumber`
                                              }
                                              component={Input}
                                              title={minMaxDescriptionTitle}
                                              validate={composeValidators(mustBeNumber, minMaxValueValidator(minValue, maxValue))}
                                            />
                                          </ParamsColumn>
                                          <ParamsColumn width={2}>
                                            <ParamsDiv>
                                                <span title={t(`unit.${param.unit}`)}>
                                                  {t(`unit.${param.unit}`)}
                                                </span>
                                            </ParamsDiv>
                                          </ParamsColumn>
                                        </ParamsRow>
                                      );
                                    }) : <ParamsRow>
                                      <ExerciseParamsDiv>
                                        {t('patientExercising.notAvailableExerciseResultParameters')}
                                      </ExerciseParamsDiv>
                                    </ParamsRow>}

                                  <DataLabel>{t('patientExercising.qualitativeComment')}</DataLabel>
                                  <QualitativeComment>
                                    <ParamsColumn width={16}>
                                      <Field
                                        fluid
                                        name={`patientExerciseResultDtos[${exerciseIx}].exerciseResultWithParamDtos[${exerciseResultTabIndex}].qualitativeComment`}
                                        placeholder={t('patientExercising.placeHolder.qualitativeComment')}
                                        component={TextArea}
                                        rows={6}
                                      />
                                    </ParamsColumn>
                                  </QualitativeComment>

                                </InnerTsaGrid>
                              </ParamContainer>

                            </Grid.Column>
                          </Grid.Row>
                        </InnerTsaGrid>
                      </Grid.Column>
                    </Grid.Row>
                  </InnerTsaGrid>
                </Grid.Column>
              </Grid.Row>
            </Accordion.Content>
          </Accordion>
        </ScrollableTsaGrid>
        <FormButtonsWrapper style={isIpad ? {bottom: '65px', width: 'calc(100% - 40px)'} : {}}>
          <Grid.Row>
            <Grid.Column>
              <Grid stackable doubling columns={2}>
                <Grid.Row className='button-row-container'>
                  <Grid.Column className='button-column-container' width={16}>
                    <div className='buttons-container'>
                      <CompositeButton
                        primary
                        type='button'
                        className='action-button'
                        disabled={submitting}
                        onClick={() => goToEditExercising()}
                        style={{marginBottom: '1rem'}}
                      >
                        {t('button.editExerciseSet')}
                      </CompositeButton>

                      <CompositeButton
                        primary
                        type='button'
                        className='action-button'
                        disabled={disabledPrev}
                        onClick={() => {
                          submitHandler(false, errors, hasValidationErrors, false);
                          previousNextHandler(-1, form);
                          resetExerciseIndexes();
                        }
                        }
                        style={{ marginBottom: '1rem' }}
                      >
                        {t('action.previous')}
                      </CompositeButton>

                      <CompositeButton
                        primary
                        type='button'
                        className='action-button'
                        disabled={disabledNext}
                        onClick={() => {
                          submitHandler(false, errors, hasValidationErrors, false);
                          previousNextHandler(1, form);
                          resetExerciseIndexes();
                        }
                        }
                        style={{ marginBottom: '1rem' }}
                      >
                        {t('action.next')}
                      </CompositeButton>

                      <CompositeButton
                        primary
                        type='button'
                        className='action-button'
                        disabled={submitting}
                        onClick={() => {
                          form.submit();
                          submitHandler(true, errors, hasValidationErrors, true);
                        }}
                        style={{marginBottom: '1rem'}}
                      >
                        {t('button.save')}
                      </CompositeButton>

                      <CompositeButton
                        type='button'
                        className='action-button'
                        secondary
                        onClick={goToPreviousPage}
                        style={{ float: 'right', marginBottom: '1rem' }}
                      >
                        {t('action.cancel')}
                      </CompositeButton>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </FormButtonsWrapper>
        <FormSpy subscription={{ values: true }} onChange={handleFormValuesChange} />
      </form>
    );
  };

  const renderSingleQuestion = (questionAnswerFieldPath: string, index: number, question: any, form: FormApi) => {
    const textMap: { [lang: string]: string } =
      {
        'de': question.text,
        'en': question.textEn || question.text,
        'fr': question.textFr || question.text,
        'it': question.textIt || question.text,
      };

    const minimumValue = question.minimumValue;
    const maximumValue = question.maximumValue;

    const minMaxDescriptionTitle = getMinMaxDescriptionTitle(minimumValue, maximumValue, t);

    return (
      <React.Fragment key={`question-fragment-${index}`}>
        <StyledLabel className='question-text' key={`question_label_${question.questionKey}`}>
          {`${index + 1}. ${textMap[language]}`}
        </StyledLabel>
        <StyledDataEntry title={minMaxDescriptionTitle} key={`question_value_${question.questionKey}`}>
          {renderAnswerByType(`${questionAnswerFieldPath}.selectedAnswer`, index, question, form)}
        </StyledDataEntry>
      </React.Fragment>
    );
  };

  const renderAnswerByType = (fieldPath: string, index: number, question: QuestionDto, form: FormApi) => {
    switch (question.questionType) {
      case 'MULTI_ANSWER':
        const multiAnswerPath = `${fieldPath}Ids`;
        const answers = question.possibleAnswers.map((answer, index) => {
            const texts: { [key: string]: string } = getAnswerDescriptionMap(answer);
            return (
              <ListItem key={`${multiAnswerPath}_${index}`}>
                <Field
                  name={multiAnswerPath}
                  component='input'
                  type='checkbox'
                  value={answer.id.toString()}
                />
                {' ' + t(texts[language])}
              </ListItem>
            );
          },
        );
        return (
          <List>
            {answers}
          </List>
        );
      case 'SINGLE_ANSWER':
        const singleAnswerPath = `${fieldPath}Ids`;
        return (
          <Field
            name={singleAnswerPath}
            component={RadioGroup}
            parse={(value: string) => [value]}
            format={extractValueFromList}
            radiowidth='50%'
            radioDefinitions={
              generateRadioButtonOptions(
                _.map(question.possibleAnswers, (value) => value.id.toString()),
                _.map(question.possibleAnswers, (value) => {
                  const texts: { [key: string]: string } = getAnswerDescriptionMap(value);
                  return texts[language];
                }),
                singleAnswerPath,
                t,
                _.map(question.possibleAnswers, (value) => value.id.toString()),
              )
            }
          />
        );
      case 'NUMBER':
        return (
          <Field
            name={`${fieldPath}s`}
            component={Input}
            parse={(value: string) => [value]}
            placeholder={t('questionAnswer.number.placeholder')}
            validate={composeValidators(
              required,
              mustBeNumber,
              minMaxValueValidator(question.minimumValue, question.maximumValue))
            }
            fluid
          />
        );
      case 'FREE_TEXT':
        return (
          <Field
            name={`${fieldPath}s`}
            component={Input}
            fluid
            placeholder={t('questionAnswer.freeText.placeholder')}
            parse={(value: string) => [value]}
            format={extractValueFromList}
          />
        );
      case 'YES_NO':
      default:
        const path = `${fieldPath}s`;
        const qa = _.get(form.getState().values, path);
        return (
          <>
            <CompositeButton
              className='yesno-button'
              type='button'
              size='mini'
              primary={answerEquals(qa, true)}
              onClick={() => setYesNoQuestionValue(path, true, form)}
            >
              {t('button.yes')}
            </CompositeButton>

            <CompositeButton
              className='yesno-button'
              type='button'
              size='mini'
              primary={answerEquals(qa, false)}
              onClick={() => setYesNoQuestionValue(path, false, form)}
            >
              {t('button.no')}
            </CompositeButton>
          </>
        );
    }
  };

  const setYesNoQuestionValue = (fieldPath: string, value: boolean, form: FormApi) => {
    form.change(fieldPath, [value]);
  };

  const answerEquals = (answers: string[], value: any): boolean => {
    return answers && answers.length === 1 && (answers[0] === value || answers[0] === '' + value);
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded && patientExerciseFormData !== undefined
        ? renderFinalForm()
        : <LoaderComponent message={t('general.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};

export default ExercisingForm;