import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import ArchiveTestingConfirmation from 'components/ArchiveTestingConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import PatientInfoRenderComponent from 'components/final-form/PatientInfoRenderComponent';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import {
  DropdownOption,
  DropdownValue,
  mapToDropdownOptionArrayWithTextGetter,
  PxDropdownSc,
} from 'components/final-form/Select';
import SpecialLabel from 'components/final-form/SpecialLabel';
import { defaultPopupStyle } from 'components/HeaderButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import InputView from 'components/InputView';
import LoaderComponent from 'components/LoaderComponent';
import ReportPdfModal from 'components/ReportPdfModal';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  DropdownProps,
  Grid,
  Icon,
  Input as SemanticInput,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Popup,
  TextArea,
} from 'semantic-ui-react';
import {
  getPatientTestingPreviewPdf,
  getPatientTestingPreviewPdfUrl,
  previewResults,
  setOverrideNormValue,
} from 'service/patientTestingResultService';
import {
  archivePatientTesting,
  getPatientTesting,
  getPatientTestingRelatives,
  saveEvaluation,
} from 'service/patientTestingService';
import { getAllQuestionSetsForPatientTesting } from 'service/teresaConfService';
import styled from 'styled-components';
import { PatientTestingStatus, QuestionSetType, QuestionType, TrueResultEnum } from 'ts-types/api.enums';
import {
  DomainPreview,
  OverrideNormValueRequest,
  PatientDto,
  PatientTestingPreview,
  PdfResponse,
  QuestionAnswerPairDto,
  QuestionSetScoreResult,
  QuestionSetsPreview,
  SetEvaluationDto,
  TestResultPreview,
  UpsertPatientTestingDto,
  UpsertQuestionSetDto,
} from 'ts-types/api.types';
import { isoToDisplayDateTime } from 'util/dateUtils';
import { errorUtils } from 'util/errorUtils';
import { isNotNull } from 'util/functionUtils';
import { composeValidators, mustBeNumber, required } from 'util/validatorUtils';

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);
      
    :hover {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);
    margin-right: 1rem;  
    
    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

const TestLabel = styled.div`
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1rem;
  color: var(--light-blue);
  text-transform: uppercase;
`;

const ResultRaw = styled(Grid.Row)`
  min-height: 0 !important;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 0 !important;
 
  div.flexEnd {
    display: flex;
    align-items: flex-end;
  }
  
  .column {
    margin-left: 5px;
  }
  
  .column-width-right {
    width: 36.25% !important;
  }
  
  @media only screen and (max-width: 1898px) {
    .column-width-right .multiple-answers {
      line-height: 18px;
    }
  }
  
  @media only screen and (max-width: 1510px) {
    .column-width-right {
      width: 34.25% !important;
    }
  }
  
  @media only screen and (max-width: 1128px) { 
    .column-width-right {
      width: 33.25% !important;
    }
  }
`;

const StyledOverrideNormValueModal = styled(Modal)`
    &.ui.modal .header, &.ui.modal .content, &.ui.modal .actions {
        background: var(--very-very-light-blue);
    }
    
    & .norm-value-edit-input > input {
        text-align: right;
    }

    & .field > .ui.input {
        &.text-align-right > input {
            text-align: right;
        }
    }
`;


const CrossReportColumn = styled(Grid.Column)`
  min-height: 30px;
  max-height: 30px;
  width: 20.5% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 0px !important;
  font-weight: 500;
  min-height: 35px;
  line-height: 35px;
`;

const CrossReportRow = styled(Grid.Row)`
  display: flex;
  align-items: center;
`;

const EvaluationSection = styled(Grid.Column)`
  display: flex;
  background-color: var(--very-light-gray);
  border-radius: 5px;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ResultsColumn = styled(Grid.Column)`
  min-height: 40px !important;
  max-height: 40px !important;
  width: 16.5% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NumberInputView = styled(InputView)`
  text-align: right;
  padding-right: 0.5rem;
`;

const EditNormValueIcon = styled(Icon)`
  float: left;
  cursor: pointer;  
`;

const initialQuestionSetPreview: QuestionSetsPreview = {
  allQuestionSets: [],
  scoreQuestionSets: [],
  notScoreQuestionSets: [],
  scoreListIncludedIndexes: "",
  nextPreviousNavigation: false,
  maxNavigationItems: 0,
};

const cancelTokenSource = axios.CancelToken.source();

const PreviewResultsPage = () => {
  const { state } = useLocation();

  const {setClinicId} = useClinicHeaderContext();

  const patientTestingId: number | undefined = state?.patientTestingId ? Number(state?.patientTestingId) : undefined;
  const patientTestingQuestionSetId: number | undefined = state?.patientTestingQuestionSetId
    ? Number(state?.patientTestingQuestionSetId)
    : undefined;
  const isIpad = useIsIpadWidthOrBelow();

  const patientExercisingId: number | undefined = state?.patientExercisingId ? Number(state?.patientExercisingId) : undefined;
  const questionSetId: number | undefined = state?.questionSetId ? Number(state?.questionSetId) : undefined;
  const patient: PatientDto = state.patient;
  const prevPath: string | undefined = state?.prevPath ? state?.prevPath : undefined;
  const { language } = useAuthContext();
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const [patientTesting, setPatientTesting] = useState<UpsertPatientTestingDto>();
  const [preview, setPreview] = useState<PatientTestingPreview>();
  const [comparePreview, setComparePreview] = useState<PatientTestingPreview>();
  const [patientTestingRelatives, setPatientTestingRelatives] = useState<DropdownOption[]>([]);
  const [selectedPatientTestingRelative, setSelectedPatientTestingRelative] =
    useState<number | undefined>(undefined);

  const [questionSetsPreview, setQuestionSetsPreview] = useState<QuestionSetsPreview>(initialQuestionSetPreview);
  const [compareQuestionSetsPreview, setCompareQuestionSetsPreview] = useState<QuestionSetsPreview>(initialQuestionSetPreview);

  const [questionSetIx, setQuestionSetIx] = useState<number>(0);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [evaluation, setEvaluation] = React.useState<string>('');

  const [previewReportPdfResponse, setPreviewReportPdfResponse] =
    useState<PdfResponse | undefined>(undefined);

  const [previewReportPdfModalOpen, setPreviewReportPdfModalOpen] = useState<boolean>(false);

  const [editNormValue, setEditNormValue] =
    useState<Partial<OverrideNormValueRequest>>();


  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [normValueErrorMessages, setNormValueErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchPatientTests(false);
  }, []);

  useEffect(() => {
    fetchComparePatientTesting();
  }, [selectedPatientTestingRelative]);

  const fetchPatientTests = async (afterOverrideNormValue: boolean) => {
    if (patientTestingId) {
      setDataLoaded(false);
      try {
        const patientTesting = await getPatientTesting(patientTestingId, cancelTokenSource);

        const patientTestingPreview = await previewResults(patientTestingId, cancelTokenSource);
        await fetchQuestionSet();
        const patientTestingRelatives =
          await getPatientTestingRelatives(patientTestingId, cancelTokenSource);

        let relativeDropDownOptions: DropdownOption[] = [];
        if (patientTestingRelatives.length && !afterOverrideNormValue) {
          setSelectedPatientTestingRelative(patientTestingRelatives[0].patientTestingId);
          relativeDropDownOptions = mapToDropdownOptionArrayWithTextGetter(
            patientTestingRelatives,
            relative => `${t('testSet.title')} - ${isoToDisplayDateTime(relative.date)}`,
            'patientTestingId',
            'patientTestingId'
          );
        }

        setPreview(patientTestingPreview);
        setPatientTesting(patientTesting);
        setEvaluation(patientTesting.evaluation);

        if (!afterOverrideNormValue) {
          setPatientTestingRelatives(relativeDropDownOptions);
        }

      } catch (e) {
        handleError(e);
      } finally {
        setDataLoaded(true);
      }
    }
  };

  const fetchQuestionSet = async () => {
    try {
      if ((questionSetId || patientTestingQuestionSetId) && (patientTestingId || patientExercisingId)) {
        const response =
          await getAllQuestionSetsForPatientTesting(patientTestingId!, cancelTokenSource);
        setQuestionSetsPreview(response);
      }
    } catch (e) {
      handleError(e);
    }
  };

  const fetchComparePatientTesting = async () => {
    try {
      if (selectedPatientTestingRelative) {
        const patientTestingCompare =
          await previewResults(selectedPatientTestingRelative, cancelTokenSource);

        const compareQuestionSets =
          await getAllQuestionSetsForPatientTesting(selectedPatientTestingRelative!, cancelTokenSource);

        setComparePreview(patientTestingCompare);
        setCompareQuestionSetsPreview(compareQuestionSets);
      } else {
        setComparePreview(undefined);
      }
    } catch (e) {
      handleError(e);
    }
  };

  const previousHandler = () => {
    setQuestionSetIx(questionSetIx - 1);
  };

  const nextHandler = () => {
    setQuestionSetIx(questionSetIx + 1);
  };

  const handleOverrideNormValueError = (error: any) => {

    if (error) {
      if (error.response && error.response.data) {
        const e = error.response.data;
        const knownErrors: Array<string> = [
          errorUtils.normValueCanNotBeNull,
          errorUtils.trueScoreResultNotFound,
          errorUtils.noTrueScoreFoundForGivenResult,
        ];

        const violations: Array<any> = e.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              setOverrideNormValueErrorMessage(t(`error.${violation.errorCode}`));
            }
          });
        } else {
          setOverrideNormValueErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setOverrideNormValueErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...normValueErrorMessages];
      errMsgs.push(errorMessage);
      setNormValueErrorMessages(errMsgs);
    } else {
      setNormValueErrorMessages([]);
    }
  };


  const handleError = (error: any) => {

    if (error) {
      if (error.response && error.response.data) {
        const e = error.response.data;
        const errorCode = e.errorCode;
        const knownErrors: Array<string> = [];

        const violations: Array<any> = e.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              setErrorMessage(t(`error.${violation.errorCode}`));
            }
          });
        }

        if (!errorMessages.length) {
          if (knownErrors.includes(errorCode)) {
            setErrorMessage(t(`error.${errorCode}`));
          } else {
            setErrorMessage(t('error.general'));
          }
        }
      } else {
        setErrorMessage(t('error.general'));
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const goToPreviousPage = () => {
    if (prevPath === '/') {
      history.push(prevPath, {clinicId: state.clinicId});
    } else if (prevPath === '/doctor/details') {
      history.push(prevPath!, { id: patient?.treaterId, clinicId: state.clinicId })
    } else {
      history.push('/patient/details', { id: patientTesting?.patientId, clinicId: state.clinicId });
    }
  };

  const archiveTesting = () => {

    const onSave = () => {
      setSuccessMsg(t('patientTesting.archiveSuccess'));
      setTimeout(() => {
        if (prevPath === '/') {
          history.push(prevPath, { clinicId: state.clinicId });
        } else if (prevPath === '/doctor/details') {
          history.push(prevPath!, { id: patient?.treaterId, clinicId: state.clinicId })
        } else {
          history.push('/patient/details', { id: patientTesting?.patientId, clinicId: state.clinicId });
        }
      }, 1200);
    };

    if (patientTestingId) {
      archivePatientTesting(patientTestingId, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e));
    }

  };

  const setPreviewReportModalOpen = async () => {
    if (!patientTestingId) {
      return;
    }

    try {
      const response = await getPatientTestingPreviewPdf(patientTestingId, cancelTokenSource);
      setPreviewReportPdfResponse(response);
      setPreviewReportPdfModalOpen(true);
    } catch (e) {
      handleError(e);
    }
  };

  const goToExportPage = () => {
    history.push('/export-testing', {
      patientId: patient.id,
      patientTestingId: patientTestingId,
      comparePatientTestingId: selectedPatientTestingRelative,
      prevPath: '/preview-results',
      backPrevPath: state.prevPath,
      patientTestingQuestionSetId: patientTestingQuestionSetId,
      clinicId: state.clinicId
    });
  }

  const hidePreviewReportModal = () => {
    setPreviewReportPdfModalOpen(false);
    setPreviewReportPdfResponse(undefined);
  };

  const setTrueResultMark = (value: string, trueResult: string, compareTrueResult?: string) => {

    let icons = [];

    if (trueResult === value) {
      icons.push(<Icon name='window close outline' />);
    } else {
      icons.push(<Icon name='square outline' />);
    }

    if (compareTrueResult === value) {
      icons.push(<Icon name='circle outline'
                       style={{ fontSize: trueResult === value ? '17px' : '14px', left: '43%' }}
      />);
    }

    return <Icon.Group size='large'>
      {icons.map(i => i)}
    </Icon.Group>;
  };

  const scoresRuleTypeRenderer = (values: string) => {
    if(isNotNull(values)){
      return (t(`trueScoreResultType.${values}`));
    }
    return "";
  }

  const trueScoreRenderer = (trueResult: string, compareTrueResult?: string) => {
    return <>
      <CrossReportColumn style={{ backgroundColor: 'var(--very-much-below-standard)' }}>
        {setTrueResultMark(TrueResultEnum.VERY_MUCH_BELOW_STANDARD, trueResult, compareTrueResult)}
      </CrossReportColumn>
      <CrossReportColumn style={{ backgroundColor: 'var(--much-below-standard)' }}>
        {setTrueResultMark(TrueResultEnum.MUCH_BELOW_STANDARD, trueResult, compareTrueResult)}
      </CrossReportColumn>
      <CrossReportColumn style={{ backgroundColor: 'var(--below-standard)' }}>
        {setTrueResultMark(TrueResultEnum.BELOW_STANDARD, trueResult, compareTrueResult)}
      </CrossReportColumn>
      <CrossReportColumn>
        {setTrueResultMark(TrueResultEnum.STANDARD, trueResult, compareTrueResult)}
      </CrossReportColumn>
      <CrossReportColumn style={{ backgroundColor: 'var(--above-standard)' }}>
        {setTrueResultMark(TrueResultEnum.ABOVE_STANDARD, trueResult, compareTrueResult)}
      </CrossReportColumn>
    </>;
  };

  const trueScoreHeaderRenderer = () => {
    return <>
      <TestLabel style={{display: 'flex', alignSelf: 'start'}}>
        {t('testResult.Schweregrad')}
      </TestLabel>
      <CrossReportRow style={{ marginLeft: '-2px', paddingRight: '4px' }}>
        <CrossReportColumn style={{ backgroundColor: 'var(--very-much-below-standard)', fontSize: '20px' }}>
          &#60;&#60;&#60;
        </CrossReportColumn>
        <CrossReportColumn style={{ backgroundColor: 'var(--much-below-standard)', fontSize: '20px' }}>
          &#60;&#60;
        </CrossReportColumn>
        <CrossReportColumn style={{ backgroundColor: 'var(--below-standard)', fontSize: '20px' }}>
          &#60;
        </CrossReportColumn>
        <CrossReportColumn style={{ fontSize: '35px' }}>
          &#8226;
        </CrossReportColumn>
        <CrossReportColumn style={{ backgroundColor: 'var(--above-standard)', fontSize: '20px' }}>
          &#62;
        </CrossReportColumn>
      </CrossReportRow>
    </>;
  };

  const trueScoreErrorCodeRenderer = (trueScoreErrorCode: string) => {
    if (trueScoreErrorCode.length > 10) {
      return (
        <Popup content={<>
          {trueScoreErrorCode}
        </>}
               on='hover'
               trigger={
                 <div>
                   {`${trueScoreErrorCode.substring(0, 11)}...`}
                 </div>
               }
               popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
               size='small'
               position='top center'
               style={defaultPopupStyle}
        />

      );
    }
    return (
      <>
        {`${trueScoreErrorCode}`}
      </>
    );
  };

  const renderAnswers = (selectedAnswers: string[]): ReactNode => {
    if (selectedAnswers.length > 1) {
      let answers: string = '';

      selectedAnswers.forEach((answer, index) => {
        if (index < selectedAnswers.length - 1) {
          answers = answers.concat(`${answer}, `);
        } else {
          answers = answers.concat(`${answer}`);
        }
      });

      return (
        <Popup
        trigger={<span>{answers}</span>}
        content={answers}
        on='hover'
        size='large'
        position='top center'
        wide='very'
      />
      );
    } else {
      let answer: string = selectedAnswers[0];

      return (
        <Popup
          trigger={<span>{answer}</span>}
          content={answer}
          on='hover'
          size='large'
          position='top center'
          wide='very'
        />
      );
    }
  };

  const handleChange = (e: any) => {
    setEvaluation(e.target.value);
  };

  const onSaveEvaluation = () => {
    const onSave = () => {
      setSuccessMsg(t('patientTesting.saveEvaluation'));
      setTimeout(() => {
        setSuccessMsg("");
      }, 1200);
    }

    const requestSaveEvaluationDto: SetEvaluationDto = {
      evaluation: evaluation,
    };

    if (patientTestingId) {
      saveEvaluation(patientTestingId, requestSaveEvaluationDto, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e));
    }
  };

  const onPatientTestingRelativeChange = (value?: DropdownValue) => {
    setSelectedPatientTestingRelative(value as number);
  };

  const getScoreQuestionSetNameMap = (scoreQuestionSet: UpsertQuestionSetDto): { [key: string]: string } => {
    if (scoreQuestionSet) {
      return {
        'de': scoreQuestionSet.name,
        'en': scoreQuestionSet.nameEn || scoreQuestionSet.name,
        'fr': scoreQuestionSet.nameFr || scoreQuestionSet.name,
        'it': scoreQuestionSet.nameIt || scoreQuestionSet.name,
      };
    }
    return {};
  };

  const getQuestionSetScoreDescMap = (questionSetScore: QuestionSetScoreResult): { [key: string]: string } => {
    if (questionSetScore) {
      return {
        'de': questionSetScore.description,
        'en': questionSetScore.descriptionEn || questionSetScore.description,
        'fr': questionSetScore.descriptionFr || questionSetScore.description,
        'it': questionSetScore.descriptionIt || questionSetScore.description,
      };
    }
    return {};
  };

  const translateDescription = (domainPrev: DomainPreview) => {
    const domainDesc = domainPrev.description;

    const domainDescriptions: { [key: string]: string } = {
      'de': domainPrev.description || domainDesc,
      'en': domainPrev.descriptionEn || domainDesc,
      'fr': domainPrev.descriptionFr || domainDesc,
      'it': domainPrev.descriptionIt || domainDesc,
    };

    return domainDescriptions[language];
  };

  const translateResultDescription = (testResultParamPreview: TestResultPreview) => {
    const resultDesc = testResultParamPreview.resultDescription;

    const resultDescriptions: { [key: string]: string } = {
      'de': testResultParamPreview.resultDescription || resultDesc,
      'en': testResultParamPreview.resultDescriptionEn || resultDesc,
      'fr': testResultParamPreview.resultDescriptionFr || resultDesc,
      'it': testResultParamPreview.resultDescriptionIt || resultDesc,
    };

    return resultDescriptions[language];
  };

  const translateQuestionDescription = (questionAnswerPair: QuestionAnswerPairDto) => {
    const questionDesc = questionAnswerPair.text;

    const questionDescriptions: { [key: string]: string } = {
      'de': questionAnswerPair.text || questionDesc,
      'en': questionAnswerPair.textEn || questionDesc,
      'fr': questionAnswerPair.textFr || questionDesc,
      'it': questionAnswerPair.textIt || questionDesc,
    };

    return questionDescriptions[language];
  };

  const translatePossibleAnswers = (questionAnswerPair: QuestionAnswerPairDto, language: string): string[] => {
    let possibleAnswers: string[] = [];

    questionAnswerPair.selectedAnswerIds.forEach((selectedAnswerId) => {
      questionAnswerPair.possibleAnswers.forEach((possibleAnswer) => {
        if (selectedAnswerId === possibleAnswer.id.toString() && !possibleAnswer.deleted) {
          const description = possibleAnswer.description;

          const possibleAnswerDescriptions: { [key: string]: string } = {
            'de': possibleAnswer.description || description,
            'en': possibleAnswer.descriptionEn || description,
            'fr': possibleAnswer.descriptionFr || description,
            'it': possibleAnswer.descriptionIt || description,
          };

          const translatedDescription = possibleAnswerDescriptions[language];
          possibleAnswers.push(translatedDescription);
        }
      });
    });

    if (!possibleAnswers.length) {
      let selectedAnswer = questionAnswerPair.selectedAnswers.join(', ');
      if (questionAnswerPair.questionType === QuestionType.YES_NO) {
        if (questionAnswerPair.selectedAnswers[0] === 'true') {
          selectedAnswer = t('button.yes');
        } else {
          selectedAnswer = t('button.no');
        }
      }
      possibleAnswers.push(selectedAnswer);
    }

    return possibleAnswers;
  };

  const onOpenOverrideNormVale = (testResultParamPreview: TestResultPreview) => {

    const request: Partial<OverrideNormValueRequest> = {
      overrideNormValue: testResultParamPreview.normValue,
      testResultId: testResultParamPreview.testResultId,
      patientTestResultId: testResultParamPreview.testId,
      reset: false,
    };

    setEditNormValue(request);
  };

  const onCloseOverrideNormValue = () => {
    setEditNormValue(undefined);
  };

  const saveOverrideNormValue = useCallback(async (values: Partial<OverrideNormValueRequest>) => {
    try {
      await setOverrideNormValue(values, cancelTokenSource);
      onCloseOverrideNormValue();
      fetchPatientTests(true);
    } catch (e) {
      handleOverrideNormValueError(e);
    }
  }, []);


  const renderOverrideNormValueFinalForm = (testResultParamPreview: TestResultPreview): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<OverrideNormValueRequest>) => saveOverrideNormValue(values)}
        initialValues={editNormValue}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<Partial<OverrideNormValueRequest>>) => renderFormContent(formProps, testResultParamPreview)}
      />
    );
  };

  const renderFormContent = ({
                               handleSubmit,
                               submitting,
                               values,
                             }: FormRenderProps<Partial<OverrideNormValueRequest>>,
                             testResultParamPreview: TestResultPreview): React.ReactNode => {

    const disableOverrideNormValue = submitting
      || (values && !values.overrideNormValue);

    const disableReset = !testResultParamPreview.originalNormValue;

    return (
      <>
        <ModalContent as='form' onSubmit={handleSubmit}>
          <TsaGrid>
            {
              normValueErrorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='error-message'>
                    <StyledErrorMessage onDismiss={() => setOverrideNormValueErrorMessage()}>
                      {normValueErrorMessages.map((err: string) => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </div>
                </Grid.Column>
              </Grid.Row>
            }
            <Grid.Row>
              <Grid.Column width={8}>
                <DataLabel>{t('previewResult.currentNormValue')}</DataLabel>
                <SemanticInput
                  type='input'
                  className='norm-value-edit-input'
                  value={
                    testResultParamPreview.originalNormValue
                      ? testResultParamPreview.originalNormValue
                      : testResultParamPreview.normValue
                  }
                  disabled={true}
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <DataLabel>{t('previewResult.newNormValue')}</DataLabel>
                <Field
                  name='overrideNormValue'
                  component={Input}
                  validate={composeValidators(required, mustBeNumber)}
                  numberInput={true}
                  autoFocus
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        </ModalContent>
        <ModalActions>
          <ActionContainer>
            <CompositeButton
              type='button'
              primary
              className='action-button'
              onClick={() => {
                const request = {
                  ...values,
                  reset: true,
                };
                saveOverrideNormValue(request);
              }}
              disabled={disableReset}
            >
              {t('button.reset')}
            </CompositeButton>
            <CompositeButton
              type='submit'
              primary
              className='action-button'
              onClick={handleSubmit}
              disabled={disableOverrideNormValue}
            >
              {t('button.save')}
            </CompositeButton>
            <CompositeButton
              type='button'
              secondary
              className='action-button'
              onClick={onCloseOverrideNormValue}
            >
              {t('button.cancel')}
            </CompositeButton>
          </ActionContainer>
        </ModalActions>
      </>
    );
  };


  const questionSetCounter = () => {
    const questionSets = patientTesting ? patientTesting.questionSetDtos : [];
    return `${questionSetIx + 1}/${questionSets.length}`;
  };

  const renderQuestionSets = () => {
    if (questionSetIx === 0 && questionSetsPreview.scoreQuestionSets.length > 0) {
      return <>
        <ResultRaw>
          <Grid.Column width={!isIpad ? 6 : 8}>
            <TestLabel>
              {t('questionSet.header')}
            </TestLabel>
          </Grid.Column>
          <Grid.Column width={2} style={{ marginLeft: '6px' }}>
            <TestLabel>
              {t('questionSetType.SCORE')}
            </TestLabel>
          </Grid.Column>
          <Grid.Column width={!isIpad ? 3 : 5}>
            {trueScoreHeaderRenderer()}
          </Grid.Column>
        </ResultRaw>
        {questionSetsPreview.scoreQuestionSets.map((scoreQuestionSet, index) => {

          const scoreQuestionSetName = getScoreQuestionSetNameMap(scoreQuestionSet)[language];

          return scoreQuestionSet.questionSetScoreResults.map((questionSetScore, sqsIx) => {

            let questionSetScoreDesc = getQuestionSetScoreDescMap(questionSetScore)[language];

            let compareTrueResult = undefined;
            if (compareQuestionSetsPreview
              && compareQuestionSetsPreview.scoreQuestionSets
              && compareQuestionSetsPreview.scoreQuestionSets.length
              && compareQuestionSetsPreview.scoreQuestionSets[index]
              && compareQuestionSetsPreview.scoreQuestionSets[index].questionSetScoreResults
              && compareQuestionSetsPreview.scoreQuestionSets[index].questionSetScoreResults.length
              && compareQuestionSetsPreview.scoreQuestionSets[index].questionSetScoreResults[sqsIx]
            ) {

              compareTrueResult = compareQuestionSetsPreview.scoreQuestionSets[index].questionSetScoreResults[sqsIx].trueResultValue;
            }

            let questionSetLabel = scoreQuestionSetName;
            if (questionSetScoreDesc && !_.isEmpty(questionSetScoreDesc)) {
              questionSetLabel = questionSetScoreDesc;
            }

            return <Grid.Row style={{ paddingBottom: '0px' }} key={index + 'score' + sqsIx}>
              <Grid.Column width={16}>
                <InnerTsaGrid>
                  <ResultRaw>
                    <Grid.Column width={!isIpad ? 6 : 8} style={{ marginLeft: '5px' }}>
                      <InputView>
                          <span title={sqsIx === 0 ? questionSetLabel : questionSetScoreDesc}>
                            {sqsIx === 0 ? questionSetLabel : questionSetScoreDesc}
                          </span>
                      </InputView>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <NumberInputView>
                        {questionSetScore.score}
                      </NumberInputView>
                    </Grid.Column>
                    <ResultsColumn width={!isIpad ? 3 : 5} style={{ marginLeft: '5px' }}>
                      <CrossReportRow>
                        {trueScoreRenderer(questionSetScore.trueResultValue, compareTrueResult)}
                      </CrossReportRow>
                    </ResultsColumn>
                  </ResultRaw>
                </InnerTsaGrid>
              </Grid.Column>
            </Grid.Row>;
          });
        })
        }
      </>;
    } else if (questionSetsPreview.notScoreQuestionSets.length > 0) {
      let tempQuestionSetIndex = questionSetIx;

      if (questionSetsPreview.scoreQuestionSets.length > 0) {
        tempQuestionSetIndex = tempQuestionSetIndex - 1;
      }
      const questionSet = questionSetsPreview.notScoreQuestionSets[tempQuestionSetIndex];
      return (
        <>
          {
            <ResultRaw>
              <Grid.Column width={8}>
                <TestLabel>
                  {t('previewResult.questions')}
                </TestLabel>
              </Grid.Column>
              <Grid.Column width={3}>
                <TestLabel>
                  {t('previewResult.answers')}
                </TestLabel>
              </Grid.Column>
            </ResultRaw>
          }
          {questionSet.questionAnswerPairs
          && questionSet.questionAnswerPairs.length
            ? questionSet.questionAnswerPairs.map((questionAnswerPair, index) => {
              const isNormalQuestionType = questionSet.questionSetType === QuestionSetType.NORMAL;
              return (
                <Grid.Row key={`questionAnswerPair-${index}`} style={{ paddingBottom: '0px' }}>
                  <Grid.Column width={16}>
                    <InnerTsaGrid>
                      <ResultRaw>
                        <Grid.Column width={8} style={{ marginLeft: '5px' }}>
                          <InputView>
                                      <span title={questionAnswerPair.text}>
                                        {translateQuestionDescription(questionAnswerPair)}
                                      </span>
                          </InputView>
                        </Grid.Column>
                        <Grid.Column width={isNormalQuestionType ? 7 : 3}>
                          <InputView style={{marginRight: "-10px"}}>
                            {questionAnswerPair.selectedAnswers ?
                              renderAnswers(translatePossibleAnswers(questionAnswerPair, language))
                              : <></>
                            }
                          </InputView>
                        </Grid.Column>
                      </ResultRaw>
                    </InnerTsaGrid>
                  </Grid.Column>
                </Grid.Row>
              );
            })
            : <>
            </>
          }
        </>
      );
    }
  };

  const renderOverrideNormValueModal = (testResultParamPreview: TestResultPreview) => {
      return (
        <StyledOverrideNormValueModal
          size='small'
          dimmer='blurring'
          open={editNormValue?.testResultId}
          onClose={onCloseOverrideNormValue}
        >
          <ModalHeader>
            {
              t(
                'previewResult.overrideNormValueTitle',
                { testResultName: translateResultDescription(testResultParamPreview) },
              )
            }
          </ModalHeader>
          {renderOverrideNormValueFinalForm(testResultParamPreview)}
        </StyledOverrideNormValueModal>
      );
  };

  const testResultWithOriginalNormValue = (originalNormValue: number) => {
    const val = Number(originalNormValue).toFixed(2);
    const trigger = <span title={val} style={{ cursor: 'pointer' }}>*</span>;
    const content = <span>{val}</span>;
    return (
      <Popup
        trigger={trigger}
        content={content}
        on='click'
        size='small'
        position='top center'
        wide='very'
      />
    );
  }

  const renderData = () => {

    const disablePrevious = !questionSetsPreview.nextPreviousNavigation || questionSetIx < 1;

    const disableNext = !questionSetsPreview.nextPreviousNavigation
      || questionSetIx >=  questionSetsPreview.maxNavigationItems - 1;

    let questionSetTitle = t('previewResult.questionSet');

    if (questionSetsPreview.allQuestionSets.length > 1) {
      if (questionSetsPreview.scoreQuestionSets.length > 0 && questionSetIx === 0) {
        questionSetTitle += ' ( ' + questionSetsPreview.scoreListIncludedIndexes + ' / '
          + questionSetsPreview.allQuestionSets.length + ' )';
      } else if (questionSetsPreview.scoreQuestionSets.length === 0 || questionSetIx > 0) {
        let correctionIndex = questionSetsPreview.scoreQuestionSets.length > 0 ? 1 : 0;
        questionSetTitle += ' ( ' + questionSetsPreview.notScoreQuestionSets[questionSetIx - correctionIndex].orderIndex + ' / '
          + questionSetsPreview.allQuestionSets.length + ' )';
      }
    }

    return (
      <div style={{ paddingLeft: '10px' }}>
      <TsaGrid>
        <Grid.Row>
          <Grid.Column>
            <Grid>
              <Grid.Column width={6} style={{ paddingLeft: '0px' }}>
                <div className='title-h1'>
                  {t('patientTesting.testResultsTitle')}
                </div>
              </Grid.Column>
              <Grid.Column width={10} textAlign={"right"}>
                <PatientInfoRenderComponent patient={patient} patientTesting={patientTesting} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Grid>
              <Grid.Column width={16} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <InnerTsaGrid>
                  <Grid.Row style={{ paddingBottom: '0px' }}>
                    <Grid.Column width={!isIpad ? 6 : 14}>
                      <DataLabel>{t('previewResult.compare')}</DataLabel>
                      <PxDropdownSc
                        value={selectedPatientTestingRelative}
                        onChange={(e: any, data: DropdownProps) => onPatientTestingRelativeChange(data.value)}
                        options={patientTestingRelatives}
                        selection
                        clearable
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </InnerTsaGrid>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>

        {questionSetsPreview.allQuestionSets.length > 0 &&
          <Grid.Row>
            <Grid.Column>
              <Grid>
                <Grid.Column width={16} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                  <InnerTsaGrid>
                    <ResultRaw>
                      <Grid.Column width={!isIpad ? 8 : 10}>
                        <div className='title-h1'>{questionSetTitle}</div>
                      </Grid.Column>
                      {!(disableNext && disablePrevious) &&
                      <Grid.Column width={!isIpad ? 7 : 5} textAlign={'left'}>
                        <CompositeButton
                          secondary
                          type='button'
                          className='action-button'
                          floated='left'
                          size='medium'
                          onClick={previousHandler}
                          disabled={disablePrevious}
                        >
                          {t('action.previous')}
                        </CompositeButton>
                        <CompositeButton
                          secondary
                          type='button'
                          className='action-button'
                          size='medium'
                          floated='left'
                          onClick={nextHandler}
                          disabled={disableNext}
                        >
                          {t('action.next')}
                        </CompositeButton>
                      </Grid.Column>
                      }
                    </ResultRaw>
                    {renderQuestionSets()}
                  </InnerTsaGrid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        }

        <Grid.Row>
          <Grid.Column>
            <Grid>
              <Grid.Column width={16} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <InnerTsaGrid>
                  {
                    preview?.domainsPreview?.map((domainPrev, index) => {

                      const compareDomainPrev = comparePreview
                      && comparePreview.domainsPreview
                        ? comparePreview.domainsPreview.find(cdp => domainPrev.domainId === cdp.domainId)
                        : undefined;

                      const compareTestResultMap: { [key: number]: TestResultPreview } = compareDomainPrev
                        ? compareDomainPrev.testResultsPreview.reduce((map: {
                          [key: number]: TestResultPreview
                        }, obj: TestResultPreview) => {
                          map[obj.testResultId] = obj;
                          return map;
                        }, {})
                        : {};

                      return (
                        <Grid.Row key={index} style={{ paddingBottom: '0px' }}>
                          <Grid.Column width={16}>
                            <InnerTsaGrid>
                              <ResultRaw>
                                <Grid.Column width={!isIpad ? 4 : 6}>
                                  {index === 0
                                    ? <TestLabel style={{ display: 'flex', alignSelf: 'start' }}>
                                      {t('previewResult.DomainTestResult')}
                                    </TestLabel>
                                    : <></>
                                  }
                                  <SpecialLabel style={{
                                    display: 'flex',
                                    alignSelf: 'end',
                                    paddingTop: index === 0 ? '8px' : '0px',
                                  }}>
                                    {translateDescription(domainPrev)}
                                  </SpecialLabel>
                                </Grid.Column>

                                <Grid.Column width={1} style={{display: 'flex', alignSelf: 'start'}}>
                                  {index === 0
                                    ? <TestLabel>
                                      {t('testresult.rawValue')}
                                    </TestLabel>
                                    : <></>
                                  }
                                </Grid.Column>

                                <Grid.Column width={2} style={{display: 'flex', alignSelf: 'start'}}>
                                  {index === 0
                                    ? <TestLabel>
                                      {t('testresult.normValue')}
                                    </TestLabel>
                                    : <></>
                                  }
                                </Grid.Column>

                                <Grid.Column width={1} style={{display: 'flex', alignSelf: 'start'}}>
                                  {index === 0
                                    ? <TestLabel>
                                      {t('testresult.valueType')}
                                    </TestLabel>
                                    : <></>
                                  }
                                </Grid.Column>

                                <Grid.Column width={!isIpad ? 3 : 5}>
                                  {index === 0
                                    ? <>
                                      {trueScoreHeaderRenderer()}
                                    </>
                                    : <></>
                                  }
                                </Grid.Column>
                                { !isIpad &&
                                  <Grid.Column width={3}>
                                    {index === 0
                                      ? <TestLabel style={{display: 'flex', alignSelf: 'start'}}>
                                        {t('testresult.qualitativeInformation')}
                                      </TestLabel>
                                      : <></>
                                    }
                                  </Grid.Column>
                                }
                              </ResultRaw>
                              {domainPrev.testResultsPreview.map((testResultParamPreview, ix) => {

                                const compareTestResultParamPreview = compareDomainPrev
                                  ? compareTestResultMap[testResultParamPreview.testResultId]
                                  : undefined;

                                const testResultId = testResultParamPreview.testResultId;

                                return (
                                  <Grid.Row key={`param-${ix}`} style={{ paddingBottom: '0px', maxHeight: '50px' }}>
                                    <Grid.Column width={16}>
                                      <InnerTsaGrid>

                                        <ResultRaw style={{ marginRight: '5px' }}>
                                          <ResultsColumn width={!isIpad ? 4 : 6}>
                                            <InputView>
                                            <span title={testResultParamPreview.resultDescription}>
                                              {translateResultDescription(testResultParamPreview)}
                                            </span>
                                            </InputView>
                                          </ResultsColumn>

                                          <ResultsColumn width={1} style={{ marginLeft: '5px' }}>
                                            <NumberInputView>
                                              {Number(testResultParamPreview.rawValue).toFixed(2)}
                                            </NumberInputView>
                                          </ResultsColumn>

                                          <ResultsColumn width={2} style={{ marginLeft: '5px' }}>
                                            <NumberInputView>
                                              {
                                                testResultParamPreview.overrideNormValue &&
                                                <>
                                                  {
                                                    editNormValue?.testResultId === testResultId &&
                                                    renderOverrideNormValueModal(testResultParamPreview)
                                                  }
                                                  <EditNormValueIcon
                                                    name='edit outline'
                                                    onClick={() => onOpenOverrideNormVale(testResultParamPreview)}
                                                    fitted
                                                  />
                                                </>
                                              }
                                              {
                                                isNotNull(testResultParamPreview.originalNormValue)
                                                && testResultWithOriginalNormValue(testResultParamPreview.originalNormValue)
                                              }
                                              {Number(testResultParamPreview.normValue).toFixed(2)}
                                            </NumberInputView>
                                          </ResultsColumn>

                                          <ResultsColumn width={1} style={{ marginLeft: '5px' }}>
                                            <NumberInputView>
                                              {scoresRuleTypeRenderer(testResultParamPreview.scoreRulesType)}
                                            </NumberInputView>
                                          </ResultsColumn>

                                          <ResultsColumn width={!isIpad ? 3 : 5} style={{ marginLeft: '5px' }}>
                                            <CrossReportRow>
                                              {trueScoreRenderer(testResultParamPreview.trueResult,
                                                compareTestResultParamPreview?.trueResult)}
                                            </CrossReportRow>
                                          </ResultsColumn>

                                          {!isIpad &&
                                            <ResultsColumn width={4} style={{ marginLeft: '5px' }}>
                                              <InputView>
                                              <span title={testResultParamPreview?.qualitativeComment}>
                                                {testResultParamPreview?.qualitativeComment}
                                              </span>
                                              </InputView>
                                            </ResultsColumn>
                                          }

                                        </ResultRaw>
                                      </InnerTsaGrid>
                                    </Grid.Column>
                                  </Grid.Row>
                                );
                              })}
                            </InnerTsaGrid>
                          </Grid.Column>
                        </Grid.Row>
                      );
                    })
                  }
                </InnerTsaGrid>
              </Grid.Column>

              <div style={{ paddingLeft: '0', marginLeft: '5px' }}>
                  <TestLabel>{t('previewResult.evaluation')}</TestLabel>
              </div>
              <Grid.Row>
                <EvaluationSection width={8}>
                  <Grid.Column>
                    <div className='ui form'>
                      <TextArea
                        rows={5}
                        placeholder='Write evaluation...'
                        value={evaluation}
                        onChange={handleChange}
                        fluid
                      />
                    </div>
                  </Grid.Column>
                </EvaluationSection>

                <Grid.Column width={8}>
                  <CompositeButton
                    type='button'
                    primary
                    className='action-button'
                    size='small'
                    style={{ marginLeft: '10px' }}
                    onClick={onSaveEvaluation}
                  >
                    {t('button.saveEvaluation')}
                  </CompositeButton>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Grid.Column>
        </Grid.Row>


        <Grid.Row>
          <Grid.Column width={16}
                       style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
          >
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className='button-row-container'>
          <Grid.Column className='button-column-container' width={16}>
            {
              successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
            }

            <ArchiveTestingConfirmation disabled={patientTesting?.status == PatientTestingStatus.ARCHIVED
              || patientTesting?.status == PatientTestingStatus.DONE}
                                        updateConfirmationText={t('patientTesting.archiveConfirmation')}
                                        submit={() => archiveTesting()} />
            {/*<CompositeButton*/}
            {/*  primary*/}
            {/*  type='button'*/}
            {/*  className='action-button'*/}
            {/*  onClick={archiveTesting}*/}
            {/*>*/}
            {/*  {t('button.archive')}*/}
            {/*</CompositeButton>*/}

            {patientTestingQuestionSetId
              ? <CompositeButton
                primary
                type='button'
                className='action-button'
                onClick={() => history.push('/answer-questions',
                  {
                    patientTestingId,
                    patientTestingQuestionSetId,
                    patientId: patientTesting?.patientId,
                    prevPath: '/preview-results',
                    clinicId: state.clinicId
                  },
                )}
                style={{ marginLeft: '1rem' }}
              >
                {t('questionAnswers.updateAnswers')}
              </CompositeButton>
              : <></>
            }

            <CompositeButton
              primary
              type='button'
              className='action-button'
              onClick={() => history.push('/patient-testing', {
                patientTestingId: patientTestingId,
                patientId: patientTesting?.patientId,
                testTemplateId: patientTesting?.testTemplateId,
                prevPath: prevPath,
                clinicId: state.clinicId
              })}
              disabled={patientTesting?.status == PatientTestingStatus.ARCHIVED
                        || patientTesting?.status == PatientTestingStatus.DONE}
              style={{ marginLeft: '1rem' }}
            >
              {t('button.editTestSet')}
            </CompositeButton>

            <CompositeButton
              type='button'
              primary
              className='action-button'
              onClick={goToExportPage}
              style={{ marginLeft: '1rem' }}
            >
              {t('button.export')}
            </CompositeButton>

            <CompositeButton
              type='button'
              className='action-button'
              secondary
              onClick={goToPreviousPage}
              style={{ marginLeft: '1rem' }}
            >
              {t('action.back')}
            </CompositeButton>
          </Grid.Column>
        </Grid.Row>
        {
          previewReportPdfModalOpen && previewReportPdfResponse
            ? <ReportPdfModal
              pdfResponses={[previewReportPdfResponse]}
              modalOpen={previewReportPdfModalOpen}
              getPdfUrl={getPatientTestingPreviewPdfUrl}
              onCloseModal={hidePreviewReportModal}
            />
            : null
        }
      </TsaGrid>
      </div>
    )
      ;
  };

  return dataLoaded
    ? renderData()
    : <LoaderComponent message={t('previewResult.loading')} />;
};

export default PreviewResultsPage;