
import _ from 'lodash';
import { useLayoutEffect, useState } from 'react';

export type FetchFunction<T> = (cancelTokenSource: any) => Promise<T>;
export type SetStateFunction<T> = (data: T) => void;

export type ArrayDirection = 1 | -1;

export const noop = () => {
};
// It covers tha cases if value is null and value is undefined
export const isNull = (value: any) => (value == null);

export const isNotNull = (value: any) => (!isNull(value));

export const findPrevOrNextElement = (array: any[], element: any, direction: ArrayDirection) => {
  // Validate input
  if (!_.isArray(array) || _.isEmpty(array)) {
    return;
  }

  // Find the index of the provided element
  const index = _.indexOf(array, element);

  // If the element is not found
  if (index === -1) {
    return;
  }

  const newIndex = index + direction;

  // Check if the new index is within the array bounds
  if (newIndex < 0 || newIndex >= array.length) {
    return; // Return if there is no adjacent element in the given direction
  }

  // Return the next element
  return _.nth(array, newIndex);
}

export function useAfterFirstRender(func: any) {
  const [retFunc, setFunct] = useState(() => ()=>{});
  useLayoutEffect(() => {
    setFunct(() => func);
  }, []);
  return retFunc;
}

