export const minChars = (numOfChars: number) => (value: string) => {
  return value && value.length < numOfChars ? " validator.minChars " + numOfChars : undefined;
};

export const getMinMaxDescriptionTitle = (minValue: any, maxValue: any, t: Function) => {

  let minAndMaxValueDescription = undefined;

  if (minValue !== null && maxValue !== null) {
    minAndMaxValueDescription = t('valueRange.minMax', {min: minValue,max: maxValue });
  } else if (minValue === null && maxValue !== null) {
    minAndMaxValueDescription = t('valueRange.maxMaxInclusive', {max: maxValue });
  } else if (maxValue === null && minValue !== null) {
    minAndMaxValueDescription = t('valueRange.minMinInclusive', {min: minValue });
  }

  return minAndMaxValueDescription;

}

export const getMinMaxDescriptionTitleWithInclusive = (
  minValue: any,
  maxValue: any,
  minInclusive: boolean,
  maxInclusive: boolean,
  t: Function
) => {

  if (minValue === null && maxValue === null) return undefined;

  const minDesc = minValue !== null
    ? t(minInclusive ? 'valueRange.minMinInclusive' : 'valueRange.min', { min: minValue })
    : undefined;
  const maxDesc = maxValue !== null
    ? t(maxInclusive ? 'valueRange.maxMaxInclusive' : 'valueRange.max', { max: maxValue })
    : undefined;

  if (minValue !== null && maxValue !== null) {
    if (minInclusive && maxInclusive) {
      return t('valueRange.minMax', { min: minValue, max: maxValue });
    }
    if (minInclusive) {
      return t('valueRange.minMaxMinInclusive', { min: minValue, max: maxValue });
    }
    if (maxInclusive) {
      return t('valueRange.minMaxMaxInclusive', { min: minValue, max: maxValue });
    }
  }

  return minDesc || maxDesc;
}