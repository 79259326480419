import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ClinicDto, TestConfExtendedSearchResponseDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/teresa-conf/testconf/extended-search`;

export const getExtendedSearchTestConfs = (source: CancelTokenSource): Promise<TestConfExtendedSearchResponseDto> => {
  return axios
  .get(`${apiUri}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExtendedSearchTestConfsForDoctor = (source: CancelTokenSource): Promise<TestConfExtendedSearchResponseDto> => {
  return axios
  .get(`${apiUri}/for-doctor`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAllClinicCodesWithDescription = (
  source: CancelTokenSource): Promise<ClinicDto[]> => {
  return axios
  .get(`${apiUri}/clinics`, { cancelToken: source.token })
  .then(response => response.data);
};
