import { useAuthContext } from 'auth/AuthContext';
import { CancelTokenSource } from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import ActivitiesTabComponent from 'components/final-form/ActivitiesTabComponent';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import InputView from 'components/InputView';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import ViewContentWrapperDiv from 'components/ViewContentWrapperDiv';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import UseIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import ExerciseTemplateModal from 'routes/patientexercising/ExerciseTemplateModal';
import TestTemplateModal from 'routes/patienttesting/TestTemplateModal';
import { Grid, Popup } from 'semantic-ui-react';
import axios from 'service/http';
import { deletePatientExercisingArchive } from 'service/patientExercisingService';
import { getPatientDetails } from 'service/patientService';
import { deletePatientTestingArchive, getPatientTestingActivities } from 'service/patientTestingService';
import { getAllQuestionSets } from 'service/teresaConfService';
import styled from 'styled-components';
import { PatientTestingActionType, PatientTestingStatus } from 'ts-types/api.enums';
import { PatientDto, PatientTestingActivityDto, QuestionSetDto } from 'ts-types/api.types';
import { isoToDisplayDateTime } from 'util/dateUtils';
import { errorUtils } from 'util/errorUtils';
import { FetchFunction, noop, SetStateFunction } from 'util/functionUtils';
import { emptyTableCell, shortLabel } from 'util/tableUtils';

const PopupTriggerContainer = styled.div`
  display: inline-block;
`;

interface RouteParams {
  patientId?: string;
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
}

const cancelTokenSource = axios.CancelToken.source();

const PatientDetailsView = (props: Props) => {

  const { state, pathname } = useLocation();

  const {setClinicId} = useClinicHeaderContext();

  const patientId: number | undefined = state?.id
    ? Number(state?.id)
    : undefined;

  const clinicId: number | undefined = state?.clinicId ? Number(state?.clinicId) : undefined;
  const testDuration: string | undefined = state?.testDuration ? String(state?.testDuration) : undefined;
  const isIpad = useIsIpadWidthOrBelow();
  const isIphone = useIsIpadWidthOrBelow(430);
  const prevPath: string | undefined = state?.prevPath ? state?.prevPath : undefined;

  const { currentUser } = useAuthContext();
  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { language } = useAuthContext();
  const currentUserId = currentUser?.id;

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isClinicAdmin = useMemo(() => currentUser
    && currentUser.clinicAdmin, [currentUser]);

  const [patient, setPatient] = React.useState<PatientDto | undefined>(undefined);
  const [patientTestingActivities, setPatientTestingActivities] = useState<PatientTestingActivityDto[]>([]);
  const [currentPatientTestingActivities, setCurrentPatientTestingActivities] = useState<PatientTestingActivityDto[]>([]);
  const [questionSets, setQuestionSets] = useState<QuestionSetDto[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [exerciseModalOpen, setExerciseModalOpen] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [confWarning, setConfWarning] = useState<string | undefined>(undefined);

  const isTreater = patient?.treaterId === currentUserId;
  const disable = isTreater || isClinicAdmin || isSystemAdmin;

  useEffect(() => {
    setClinicId(clinicId);
    fetchData();
  }, []);

  const fetchAndSetData = async (
    fetchFunction: FetchFunction<any>,
    setStateFunction: SetStateFunction<any>,
    cancelTokenSource: CancelTokenSource,
    showError: boolean) => {

    try {
      const data = await fetchFunction(cancelTokenSource);
      setStateFunction(data);
    } catch (error) {
      if (showError) {
        handleError(error.response?.data);
      } else {
        setConfWarning(t('warning.CONF_SERVER_PROBLEM'));
      }
    }
  };

  const fetchData = useCallback(async () => {
    setDataLoaded(false);

    if (patientId) {
        const results = await Promise.allSettled([
          fetchAndSetData(() => getPatientDetails(patientId, cancelTokenSource), setPatient, cancelTokenSource, true),
          fetchAndSetData(() => getPatientTestingActivities(patientId, cancelTokenSource), setPatientTestingActivities, cancelTokenSource, true),
          fetchAndSetData(getAllQuestionSets, setQuestionSets, cancelTokenSource, false),
        ]);
    }

    setDataLoaded(true);

  }, [patientId]);

  useEffect(() => {
    setCurrentPatientTestingActivities(patientTestingActivities.filter(p => p.status !== PatientTestingStatus.ARCHIVED))
  }, [patientTestingActivities]);

  const onTabChange = (index: number) => {
    switch (index) {
      case 0:
        setCurrentPatientTestingActivities(patientTestingActivities);
        return;
      case 1:
        setCurrentPatientTestingActivities(patientTestingActivities
        .filter(p => p.status !== PatientTestingStatus.ARCHIVED))
        return;
      case 2:
        setCurrentPatientTestingActivities(patientTestingActivities
        .filter(p => p.status === PatientTestingStatus.ARCHIVED))
        return;
    }
  }

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const onCloseExerciseModal = () => {
    setExerciseModalOpen(false);
  };

  const goToPreviousPage = () => {
    if (prevPath === '/') {
      push('/');
    } else if (isSystemAdmin && patient && patient.clinicId) {
      push('/patient/search', { clinicId: patient.clinicId });
    } else {
      push('/patient/search', { clinicId: state.clinicId });
    }
  };

  const goToUpsertPatient = useCallback(() => {

    push(`/patient`, { id: patientId, prevPath: pathname, clinicId: patient ? patient.clinicId : clinicId });
  }, [patient, clinicId]);

  const handleError = useCallback((error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.patientNotFound,
        errorUtils.doctorNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  }, []);

  const setErrorMessage = useCallback((errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  }, []);

  const renderPatientDetails = () => {
    if (patient) {
      return (
        <TsaGrid verticalAlign='middle'>
          <Grid.Row style={isIphone ? {paddingBottom: 0} : {}}>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('patient.details.title')}</div>
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row style={isIphone ? {paddingBottom: 0} : {}}>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Column width={4}>
                  <InnerTsaGrid>
                    {!isIphone &&
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.details.teresaId')}</DataLabel>
                          <InputView>{patient.id}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                    }
                    <Grid.Row  width={16} style={isIphone ? {paddingBottom: 0} : {}}>
                      <Grid.Column>
                        <DataLabel>{t('patient.details.originId')}</DataLabel>
                        <InputView>{patient.originId}</InputView>
                      </Grid.Column>
                    </Grid.Row>

                    {!isIphone &&
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.details.owner')}</DataLabel>
                          <InputView>{patient.treaterName}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                    }
                  </InnerTsaGrid>
                </Grid.Column>
                {!isIphone &&
                <Grid.Column width={4}>
                  <InnerTsaGrid>
                    <Grid.Row style={isIphone ? {paddingBottom: 0} : {}}>
                      <Grid.Column width={16}>
                        <DataLabel>{t('patient.gender')}</DataLabel>
                        <InputView>{t(`gender.${patient.gender}`)}</InputView>
                      </Grid.Column>
                    </Grid.Row>


                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>
                            {t('patient.details.clinic')}
                          </DataLabel>
                          <InputView>{patient.clinicName}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                  </InnerTsaGrid>
                </Grid.Column>
                }
                <Grid.Column width={4}>
                  <InnerTsaGrid>
                    {!isIphone &&
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.schoolYears')}</DataLabel>
                          <InputView>{patient.schoolYears}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                    }
                    <Grid.Row>
                      <Grid.Column width={16} style={isIphone ? {marginTop: 0} : {marginTop: "30px"}}>
                        <CompositeButton
                          type='button'
                          className='action-button'
                          primary
                          disabled={!disable}
                          onClick={goToUpsertPatient}
                        >
                          {t('patient.details.updateButton')}
                        </CompositeButton>
                      </Grid.Column>
                    </Grid.Row>
                  </InnerTsaGrid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      );
    }
  };

  const activitiesRowGetter = ({ index }: any) => {

    if (currentPatientTestingActivities.length > 0) {
      Object.assign(currentPatientTestingActivities[index], { index: index + 1 });

      return currentPatientTestingActivities[index];
    }
  };

  const activitiesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const dateTimeCellRenderer = ({ rowData }: any) => {
    return <div>{isoToDisplayDateTime(rowData.activityDate)}</div>
  };

  const doctorCellRenderer = ({ rowData }: any) => {
    return (
      <div>{`${rowData.doctor}`}</div>
    );
  };


  const patientActivityCellRenderer = ({ rowData: { status, activityRecordType } }: any) => {

    if (activityRecordType === 'TESTING') {
      switch (status) {
        case PatientTestingStatus.OPEN:
        case PatientTestingStatus.RUNNING:
        case PatientTestingStatus.COMPLETED:
        case PatientTestingStatus.ARCHIVED:
          return <div>{isIpad ? t('patientTesting.activity.TestingShort') : t('patientTesting.activity.Testing')}</div>;

      }
    } else if (activityRecordType === 'EXERCISING') {
      switch (status) {
        case PatientTestingStatus.OPEN:
        case PatientTestingStatus.RUNNING:
        case PatientTestingStatus.COMPLETED:
        case PatientTestingStatus.ARCHIVED:
          return <div>{isIpad ? t('patientExercising.activity.ExercisingShort') : t('patientExercising.activity.Exercising')}</div>
      }
    } else {
      return <div>{isIpad ? t('patientExercising.activity.PatientModifiedShort') : t('patientExercising.activity.PatientModified')}</div>;
    }

  };

  const getProgressLabel = () => {
    return (
      isIpad
        ? shortLabel(t('patient.details.testProgress'), t('patient.details.shortTestProgress'), true)
        : t('patient.details.testProgress')
    );
  };

  const testProgressCellRenderer = ({ rowData }: any) => {
    const testProgress = rowData.testProgress;
    const formattedTestProgress = rowData.testProgress ? rowData.testProgress.split('(')[0] : null;
    return (
      <div>{!testProgress ? 'n/a' : isIpad ? formattedTestProgress : testProgress}</div>
    );
  };

  const patientTestAgeRenderer = ({ rowData }: any) => {
    const patientTestAge = rowData.patientTestAge;

    return (
      <div>{patientTestAge ? `${patientTestAge}` : emptyTableCell()}</div>
    );
  };

  const patientSchoolYearsRenderer = ({ rowData }: any) => {
    const patientSchoolYears = rowData.patientSchoolYears;

    return (
      <div>{patientSchoolYears ? `${patientSchoolYears}` : emptyTableCell()}</div>
    );
  };

  const getQuestionSetNames = (questionSets: QuestionSetDto[]) => {
    const questionSetNames: string[] = [];

    if (questionSets.length) {
      questionSets.forEach(questionSet => {
        const questionSetNameMap: { [lang: string]: string } =
          {
            'de': questionSet.name,
            'en': questionSet.nameEn || questionSet.name,
            'fr': questionSet.nameFr || questionSet.name,
            'it': questionSet.nameIt || questionSet.name,
          };

        questionSetNames.push(questionSetNameMap[language]);
      });

      return <div>{questionSetNames.join(', ')}</div>;
    }

    if (confWarning) {
      return <div>{confWarning}</div>
    }

    return '';
  };

  const sortQuestionSetsByOrderIndex = (data: QuestionSetDto[], order: number[]) => {
    const lookup = order.reduce((acc: any, id, index) => {
      acc[id] = index;
      return acc;
    }, {});

    return data.sort((qs1, qs2) => {
      const aIndex = lookup[qs1.id];
      const bIndex = lookup[qs2.id];
      return aIndex - bIndex;
    });
  }

  const actionsCellRenderer = (data: any) => {
    const { rowData: { id, actionType, patientTestingId, patientTestingQuestionSetId, questionSetId, questionSetIds,
      testTemplateId, exerciseTemplateId, hasAnswers, patientExercisingId, activityRecordType, hasTestResult,
      onlyQuestions, testProgress, exerciseProgress } } = data;

    const patientId = patient?.id;
    const hasQuestions = (questionSetIds != undefined && questionSetIds.length > 0);

    let questionSetDtos: QuestionSetDto[] = [];
    if (questionSetIds && questionSetIds.length) {
      questionSetDtos =  questionSets.filter(q => questionSetIds.includes(q.id));
      questionSetDtos = sortQuestionSetsByOrderIndex(questionSetDtos, questionSetIds);
    }
    const questionSetNames = getQuestionSetNames(questionSetDtos);
    const hasExport = hasTestResult || hasAnswers;

    const trigger = <PopupTriggerContainer>
      <InnerTableActionButton
        message={t(hasAnswers
          ? 'questionAnswers.updateAnswers'
          : 'patientTesting.answerQuestions')}
        onConfirm={() => push('/answer-questions',
          { patientTestingId, patientTestingQuestionSetId, patientId, prevPath: '/patient/details', clinicId: state.clinicId },
        )}
        divider={hasExport}
        disabled={!!confWarning}
      />
    </PopupTriggerContainer>

    const renderAnswerQuestionsButton = () => {
      return (hasQuestions) && (
          <Popup
            trigger={trigger}
            popperModifiers={[{ name: 'preventOverflow', options: {boundary: 'patientDetailsTable'} }]}
            content={questionSetNames}
            on='hover'
            size='small'
            position='top center'
          />
      );
    };

    const renderReportButton = (showAnyway: boolean, divider: boolean) => {
      return (showAnyway || (hasAnswers || hasTestResult)) && (
        <InnerTableActionButton
          message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
          onConfirm={() => push('/preview-results', {
            testDuration: testDuration,
            patientTestingQuestionSetId: patientTestingQuestionSetId,
            patientTestingId: patientTestingId,
            questionSetId: questionSetId,
            patient: patient,
            prevPath: '/patient/details',
            clinicId: state.clinicId
          })}
          divider={divider}
        />
      );
    };

    const deletePatientTestingArchives = (id: number) => (): void => {
      deletePatientTestingArchive(id, cancelTokenSource)
      .then(() => {
        fetchData();
      });
    };

    const renderDeletePatientTestingButton = () => {
      return(
        <>
          <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                    confirmAction={deletePatientTestingArchives(patientTestingId)}
                                    deleteConfirmationText={t('patientActivity.confirmDelete', { id: id })}
                                    position={'top left'} />
        </>
      );
    };

    const deletePatientExercisingArchives = (id: number) => (): void => {
      deletePatientExercisingArchive(id, cancelTokenSource)
      .then(() => {
        fetchData();
      });
    };

    const renderDeletePatientExercisingButton = () => {
      const patientTestingId = data.rowData.patientExercisingId;
      return(
        <>
          <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                    confirmAction={deletePatientExercisingArchives(patientTestingId)}
                                    deleteConfirmationText={t('patientActivity.confirmDelete', { id: data.id })}
                                    position={'top left'} />
        </>
      );
    };

    const renderExportButton = (isDeleteButtonExisting: boolean) => {
      return <>
        <InnerTableActionButton
          message={t('previewResult.export')}
          onConfirm={() => push('/export-testing', {
            patientId: patientId,
            patientTestingId: patientTestingId,
            prevPath: '/patient/details',
            clinicId: state.clinicId
          })}
          divider={isDeleteButtonExisting}
        />
      </>;
    };

    if (activityRecordType === 'TESTING') {
      switch (actionType) {

        case PatientTestingActionType.START:
          return (
            <div className='row-actions'>
              {!onlyQuestions && <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.START}`)}
                onConfirm={() => push('/start-testing', {
                  patientTestingId: patientTestingId,
                  patientTestingQuestionSetId: patientTestingQuestionSetId,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={(hasQuestions && !isIphone) || hasAnswers || hasTestResult}
              />}
              {!isIphone && renderAnswerQuestionsButton()}
              {renderReportButton(false, false)}
            </div>
          );
        case PatientTestingActionType.CONTINUE:
          return (
            <div className='row-actions'>
              {!onlyQuestions && <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CONTINUE}`)}
                onConfirm={() => push('/start-testing', {
                  patientTestingId: patientTestingId,
                  patientTestingQuestionSetId: patientTestingQuestionSetId,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={true}
              />}

              {!isIphone && renderAnswerQuestionsButton()}
              {renderReportButton(true, !isIphone)}
              {!isIphone && renderExportButton(false)}
            </div>
          );
        case PatientTestingActionType.CREATE_REPORT:
          return (
            <div className='row-actions'>
              {!onlyQuestions && <InnerTableActionButton
                message={t('button.edit')}
                onConfirm={() => push('/start-testing', {
                  patientTestingId: patientTestingId,
                  patientTestingQuestionSetId: patientTestingQuestionSetId,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={true}
              />}
              {renderReportButton(true, !isIphone)}
              {
                (!isIphone && !isIpad) ?
                  <InnerTableActionButton
                    message={t('button.editTestSet')}
                    onConfirm={() => push('/patient-testing', {
                      patientTestingId, patientId, testTemplateId, prevPath: '/patient/details', clinicId: state.clinicId
                    })}
                    divider={hasQuestions || hasTestResult}
                  /> : ""
              }
              {!isIphone && renderAnswerQuestionsButton()}
              {!isIphone && renderExportButton(false)}
            </div>
          );
        case PatientTestingActionType.ARCHIVED:
          return (
            !isIphone &&
            <div className='row-actions'>
              {renderReportButton(true, hasTestResult)}
              {renderExportButton(true)}
              {renderDeletePatientTestingButton()}
            </div>
          );
        case PatientTestingActionType.OPEN_REPORT:
          return (
            !isIphone &&
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.OPEN_REPORT}`)}
                onConfirm={noop}
                divider={hasTestResult}
              />
              {renderExportButton(false)}
            </div>
          );
      }
    } else if (activityRecordType === 'EXERCISING') {
      switch (actionType) {

        case PatientTestingActionType.START:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.START}`)}
                onConfirm={() => push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={!isIphone}
              />
              {
                (!isIphone) ?
                  <InnerTableActionButton
                    message={t('button.editExerciseSet')}
                    onConfirm={() => push('/exercise-testing', {
                      patientExercisingId,
                      patientId,
                      exerciseProgress,
                      exerciseTemplateId,
                      prevPath: '/patient/details',
                      clinicId: state.clinicId
                    })}
                  /> : ""
              }
            </div>
          );
        case PatientTestingActionType.CONTINUE:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CONTINUE}`)}
                onConfirm={() => push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={!isIphone}
              />
              {!isIphone &&
                <InnerTableActionButton
                  message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                  onConfirm={() => push('/preview-exercising', {
                    patientExercisingId: patientExercisingId,
                    questionSetId: questionSetId,
                    patient: patient,
                    prevPath: '/patient/details',
                    clinicId: state.clinicId
                  })}
                  divider={!isIpad && !isIphone}
                />
              }
              {
                (!isIpad && !isIphone) ?
                  <InnerTableActionButton
                    message={t('button.editExerciseSet')}
                    onConfirm={() => push('/exercise-testing', {
                      patientExercisingId,
                      patientId,
                      exerciseProgress,
                      exerciseTemplateId,
                      prevPath: '/patient/details',
                      clinicId: state.clinicId
                    })}
                  /> : ""
              }
            </div>
          );
        case PatientTestingActionType.CREATE_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t('button.edit')}
                onConfirm={() => push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={!isIphone}
              />
              {!isIphone &&
                <InnerTableActionButton
                  message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                  onConfirm={() => push('/preview-exercising', {
                    patientExercisingId: patientExercisingId,
                    questionSetId: questionSetId,
                    patient: patient,
                    testProgress: testProgress,
                    prevPath: '/patient/details',
                    clinicId: state.clinicId
                  })}
                  divider={!isIpad && !isIphone}
                />
              }
              {
                (!isIpad && !isIphone) ?
                  <InnerTableActionButton
                    message={t('button.editExerciseSet')}
                    onConfirm={() => push('/exercise-testing', {
                      patientExercisingId,
                      patientId,
                      exerciseProgress,
                      exerciseTemplateId,
                      prevPath: '/patient/details',
                      clinicId: state.clinicId
                    })}
                  /> : ""
              }
            </div>
          );
        case PatientTestingActionType.ARCHIVED:
          return (
            !isIphone &&
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => push('/preview-exercising', {
                  patientExercisingId: patientExercisingId,
                  questionSetId: questionSetId,
                  patient: patient,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={true}
              />
              {renderDeletePatientExercisingButton()}
            </div>
          );
        case PatientTestingActionType.OPEN_REPORT:
          return (
            !isIphone &&
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.OPEN_REPORT}`)}
                onConfirm={noop}
                divider={hasQuestions}
              />
            </div>
          );
      }
    }
  }


  const renderActivities = (): JSX.Element => {

    let columns: React.ReactNode[] = [
      {
        width: 120,
        flexShrink: 0,
        label: t('dashboard.dateTime'),
        dataKey: 'patientTestingActivityDate',
        cellRenderer: dateTimeCellRenderer,
      }
    ];

    if (!isIphone) {
      columns.push(
        {
          width: 45,
          flexShrink: 0,
          label: t('dashboard.age'),
          dataKey: 'patientTestAge',
          cellRenderer: patientTestAgeRenderer,
        },
        {
          width: 55,
          flexShrink: 0,
          label: t('dashboard.schoolYearsShort'),
          dataKey: 'patientSchoolYears',
          cellRenderer: patientSchoolYearsRenderer,
        },
      )
    }

    if (!isIphone) {
      columns.push(
        {
          width: 250,
          flexGrow: 1,
          label: t('sidebar.user.roles.ROLE_DOCTOR'),
          dataKey: 'doctor',
          cellRenderer: doctorCellRenderer,
        },
      );
    }

    columns.push(
      {
        width: isIphone ? 20 : 100,
        flexGrow: isIphone ? 0 : 1,
        label: (isIphone) ? t('patient.activityShort') : t('patient.activity'),
        dataKey: 'status',
        cellRenderer: patientActivityCellRenderer,
      },
      {
        width: isIpad ? 60 : 150,
        flexGrow: 0,
        label: getProgressLabel(),
        dataKey: 'testProgress',
        cellRenderer: testProgressCellRenderer,
      },
      {
        width: isIphone ? 150 : isIpad ? 309 : 500,
        flexShrink: 0,
        label: t('patient.action'),
        dataKey: 'actionType',
        cellRenderer: actionsCellRenderer,
      },
    );

    return (
      <>
        <div style={{display: "flex", justifyContent: "space-between", flexDirection: isIphone ? 'column-reverse' : undefined}}>
          <ActivitiesTabComponent onTabChange={onTabChange} />
          <div style={isIphone ? {} : isIpad ? {textAlign: 'right'} : {}}>
            <CompositeButton
              type='button'
              className='action-button'
              primary
              onClick={() => setModalOpen(true)}
              style={isIpad ? {marginBottom: "10px"} : {marginRight: "30px"}}
            >
              {t(`patientTesting.actionType.${PatientTestingActionType.CREATE}`)}
            </CompositeButton>
            <CompositeButton
              type='button'
              className='action-button'
              primary
              onClick={() => setExerciseModalOpen(true)}
            >
              {t('button.newExercising')}
            </CompositeButton>
          </div>
        </div>
        <div id='patientDetailsTable' className='data-table'>
          <VirtualizedTable
            rowCount={currentPatientTestingActivities.length}
            rowGetter={activitiesRowGetter}
            rowRender={activitiesRowRenderer}
            rowHeight={48}
            columns={columns}
          />
        </div>
        <div className='page-actions'>
          <CompositeButton
            type='button'
            className='action-button'
            secondary
            onClick={goToPreviousPage}
            style={{ float: 'right' }}
          >
            {t('action.back')}
          </CompositeButton>
        </div>
      </>
    );
  };

  return (
    <ViewContentWrapperDiv>
      {dataLoaded
        ? <React.Fragment>
          {renderPatientDetails()}
          {renderActivities()}
        </React.Fragment>
        : <LoaderComponent message={t('patient.loading')} />
      }
      {modalOpen &&
        <TestTemplateModal
          clinicId={patient!.clinicId}
          patientId={patient?.id}
          modalOpen={modalOpen}
          prevPath={'/patient/details'}
          onCloseModal={onCloseModal}
        />
      }
      {exerciseModalOpen &&
        <ExerciseTemplateModal
          clinicId={patient!.clinicId}
          patientId={patient?.id}
          exerciseModalOpen={exerciseModalOpen}
          prevPath={'/patient/details'}
          onCloseExerciseModal={onCloseExerciseModal}
        />
      }
    </ViewContentWrapperDiv>
  );
};

export default PatientDetailsView;