import { useAuthContext } from 'auth/AuthContext';
import axios, { CancelTokenSource } from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import ImageDropZone from 'components/final-form/ImageDropZone';
import { generateRadioButtonOptions } from 'components/final-form/Radio';
import RadioGroup from 'components/final-form/RadioGroup';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import InnerFormTable from 'components/InnerFormTable';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import { FormApi } from 'final-form';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import ClinicConfigTabComponent from 'routes/clinic/ClinicConfigTabComponent';
import { Grid } from 'semantic-ui-react';
import { getClinicConf, saveClinicConf } from 'service/clinicConfService';
import {
  getAllExerciseTemplates,
  getAllQuestionSets,
  getAllTestTemplates,
  getExerciseConfs,
  getTestConfs,
} from 'service/teresaConfService';
import { HideNumbers, InvoiceConfigType, ScopeType } from 'ts-types/api.enums';
import {
  ClinicConfDto,
  ExerciseConfDto,
  ExerciseTemplateDto,
  QuestionSetDto,
  TestConfDto,
  TestTemplateDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { FetchFunction, noop, SetStateFunction } from 'util/functionUtils';
import { multiLanguageInnerTableCellRenderer } from 'util/tableUtils';

export enum ConfigView {
  GENERAL = 'general',
  TESTING = 'test',
  REHABILITATION = 'exercise'
}

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const ClinicConfigView = (props: Props) => {

  const { state } = useLocation();
  const { language } = useAuthContext();
  const { setClinicId, clinicInfo } = useClinicInfoContext();
  const isIpad = useIsIpadWidthOrBelow();

  const clinicId: number | undefined = state?.clinicId ? Number(state?.clinicId) : undefined;
  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const invoicePackageTypeLabels: Array<string> = useMemo(() => Object.values(InvoiceConfigType), []);

  const invoicePackageTypes = useMemo(() =>
    stringArrayToDropdownOptionArray(invoicePackageTypeLabels, t, 'invoicePackageType'), []);
  const hideNumbersLabels: Array<HideNumbers> = Object.values(HideNumbers);
  const hideNumbersDefinitions = generateRadioButtonOptions(hideNumbersLabels,
    'hideNumber', t, 'previewResult.hideNumber', hideNumbersLabels);

  const initialClinicConfValues: Partial<ClinicConfDto> = {
    hideNumbers: HideNumbers.DONT_HIDE,
  };

  const [activeView, setActiveView] = React.useState<ConfigView>(ConfigView.GENERAL);
  const [clinicConf, setClinicConf] = React.useState<Partial<ClinicConfDto> | undefined>(initialClinicConfValues);
  const [testConfs, setTestConfs] = React.useState<TestConfDto[]>([]);
  const [testTemplates, setTestTemplates] = React.useState<TestTemplateDto[]>([]);
  const [questionSets, setQuestionSets] = React.useState<QuestionSetDto[]>([]);
  const [exerciseConfs, setExerciseConfs] = React.useState<ExerciseConfDto[]>([]);
  const [exerciseTemplates, setExerciseTemplates] = React.useState<ExerciseTemplateDto[]>([]);
  const [testConfsCount, setTestsCount] = React.useState<number>(0);
  const [testQuestionsCount, setQuestionCount] = React.useState<number>(0);
  const [testTemplatesCount, setTestTemplateCount] = React.useState<number>(0);

  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [generalErrorMessages, setGeneralErrorMessages] = useState<string[]>([]);
  const [testOrRehabErrorMessages, setTestOrRehabErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined);
    fetchData();
  }, []);

  const fetchAndSetData = async (
    fetchFunction: FetchFunction<any>,
    setStateFunction: SetStateFunction<any>,
    cancelTokenSource: CancelTokenSource,
    configView?: ConfigView) => {

    try {
      const data = await fetchFunction(cancelTokenSource);
      setStateFunction(data);
    } catch (error) {
      handleError(error.response?.data, configView);
    }
  };

  const fetchData = async () => {
    setFormDataLoaded(false);

    if (clinicId) {
      setClinicId(clinicId);

      const results = await Promise.allSettled([
        fetchAndSetData(() => getClinicConf(clinicId, cancelTokenSource), setClinicConf, cancelTokenSource),
        fetchAndSetData(getTestConfs, setTestConfs, cancelTokenSource),
        fetchAndSetData(getAllTestTemplates, setTestTemplates, cancelTokenSource),
        fetchAndSetData(getExerciseConfs, setExerciseConfs, cancelTokenSource),
        fetchAndSetData(getAllExerciseTemplates, setExerciseTemplates, cancelTokenSource),
        fetchAndSetData(getAllQuestionSets, setQuestionSets, cancelTokenSource),
      ]);
    }

    setFormDataLoaded(true);
  };


  const handleError = useCallback((error: any, configView?: ConfigView) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!testOrRehabErrorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs, configView);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[], configView?: ConfigView) => {

    if (errMsgs) {
      if (configView === ConfigView.GENERAL) {
        setGeneralErrorMessages(errMsgs);
      } else {
        setTestOrRehabErrorMessages(errMsgs);
      }
    } else {
      setGeneralErrorMessages([]);
      setTestOrRehabErrorMessages([]);
    }
  };

  const getFineInvalidTypeError = (errorCode: string) => {
    if (errorCode === 'file-invalid-type') {
      setErrorMessage([t(`error.${errorUtils.fileTypeInvalid}`)]);
    }
  };

  const renderConfTable = (): React.ReactNode => {

    let filteredConfs: TestConfDto[] | ExerciseConfDto[] = [];
    if (ConfigView.TESTING === activeView) {
      filteredConfs = testConfs;
      setTestsCount(testConfs.length);
    } else if (ConfigView.REHABILITATION === activeView) {
      filteredConfs = exerciseConfs;
      setTestsCount(exerciseConfs.length);
    }

    return (
      <InnerFormTable id='test-conf'
                      numOfCells={2}
                      values={filteredConfs}
                      visibleRows={6}
                      columns={[
                        {
                          width: 2,
                          label: '#',
                          dataKey: 'id',
                        },
                        {
                          width: 10,
                          label: t('testconf.description'),
                          oneLine: true,
                          dataKey: 'description',
                          cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                        },
                        {
                          width: 3,
                          label: t(`clinic.config.${activeView}ConfResult`),
                          dataKey: activeView === ConfigView.TESTING ? 'numOfTestResults' : 'numOfExerciseResults',
                        },
                      ]} />
    );
  };

  const renderTestTemplateTable = (): React.ReactNode => {

    let filteredTemplates: TestTemplateDto[] | ExerciseTemplateDto[] = [];
    if (ConfigView.TESTING === activeView) {
      filteredTemplates = testTemplates;
      setTestTemplateCount(testTemplates.length);
    } else if (ConfigView.REHABILITATION === activeView) {
      filteredTemplates = exerciseTemplates;
      setTestTemplateCount(exerciseTemplates.length);
    }

    return (
      <InnerFormTable id='test-template'
                      numOfCells={2}
                      values={filteredTemplates}
                      visibleRows={6}
                      columns={[
                        {
                          width: 2,
                          label: '#',
                          dataKey: 'id',
                        },
                        {
                          width: 10,
                          label: t('testconf.description'),
                          oneLine: true,
                          dataKey: 'description',
                          cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                        },
                        {
                          width: 3,
                          label: t(`clinic.config.${activeView}Conf`),
                          dataKey: activeView === ConfigView.TESTING ? 'numOfTestConfs' : 'numOfExerciseConfs',
                        },
                      ]} />
    );
  };

  const renderQuestionSetTable = (): React.ReactNode => {

    let filteredQuestionSets: QuestionSetDto[] = [];
    if (ConfigView.TESTING === activeView) {
      filteredQuestionSets = questionSets.filter(conf => conf.scopeType === ScopeType.TEST);
      setQuestionCount(filteredQuestionSets.length);
    } else if (ConfigView.REHABILITATION === activeView) {
      filteredQuestionSets = questionSets.filter(conf => conf.scopeType === ScopeType.REHAB);
      setQuestionCount((filteredQuestionSets.length));
    }

    return (
      <InnerFormTable id='question-set'
                      numOfCells={2}
                      values={filteredQuestionSets}
                      visibleRows={6}
                      columns={[
                        {
                          width: 2,
                          label: '#',
                          dataKey: 'id',
                        },
                        {
                          width: 10,
                          label: t('questionSet.name'),
                          oneLine: true,
                          dataKey: 'description',
                          cellRenderer: multiLanguageInnerTableCellRenderer('name', language),
                        },
                        {
                          width: 3,
                          label: t('questionSet.numOfQuestions'),
                          dataKey: 'numOfQuestions',
                        },
                      ]} />
    );
  };

  const imageSizeExceeded = useCallback((values: ClinicConfDto) => {

    if (values.image && values.image.size >= 1048576) {

      setTestOrRehabErrorMessages([t(`error.${errorUtils.fileSizeExceeded}`)]);

      return true;
    }

    return false;
  }, []);

  const handleSubmit = useCallback(async (
    values: ClinicConfDto,
    form: FormApi<ClinicConfDto, Partial<ClinicConfDto>>) => {

    let saveValues: ClinicConfDto = {
      ...values,
      imageView: 'imageView',
    };

    const imageSizeExceed = imageSizeExceeded(values);

    if (imageSizeExceed) {
      return;
    }

    const onSave = () => {
      setSuccessMsg(t('clinicConf.onUpdate'));
      form.change('image', undefined);
      setTimeout(() => {
        setSuccessMsg(undefined);
      }, 1200);
    };

    if (clinicId) {
      await saveClinicConf(saveValues, cancelTokenSource)
      .then(onSave)
      .catch(noop);
    }
  }, []);

  const renderViews = (): React.ReactNode => {

    if (ConfigView.GENERAL !== activeView) {
      return <>
        {testOrRehabErrorMessages.length > 0 &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='error'>
                <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                  {testOrRehabErrorMessages.map((err: string) => <div key={err}>{err}</div>)}
                </StyledErrorMessage>
              </div>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid stackable doubling columns={2}>
              <Grid.Column width={8} style={isIpad ? { paddingBottom: '0' } : {}}>
                <InnerTsaGrid>
                  <Grid.Row>
                    <Grid.Column width={15}>
                      <DataLabel>{t(`clinic.config.${activeView}Conf`) + ` (${testConfsCount})`}</DataLabel>
                      {renderConfTable()}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={15}>
                      <DataLabel>{t('clinic.config.questionSets') + ` (${testQuestionsCount})`}</DataLabel>
                      {renderQuestionSetTable()}
                    </Grid.Column>
                  </Grid.Row>
                </InnerTsaGrid>
              </Grid.Column>

              <Grid.Column width={8} style={isIpad ? { paddingTop: '0' } : {}}>
                <InnerTsaGrid>
                  <Grid.Row>
                    <Grid.Column width={15}>
                      <DataLabel>{t(`clinic.config.${activeView}Templates`) + ` (${testTemplatesCount})`}</DataLabel>
                      {renderTestTemplateTable()}
                    </Grid.Column>
                  </Grid.Row>
                </InnerTsaGrid>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </>;
    }
  };

  const handleTabChange = (view: ConfigView) => {
    setActiveView(view);
  };

  const renderClinicContentForm = (): JSX.Element => {

    return (
      <FinalForm
        onSubmit={(values: ClinicConfDto, form: FormApi<ClinicConfDto, Partial<ClinicConfDto>>) => handleSubmit(values, form)}
        initialValues={clinicConf}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<ClinicConfDto>) => renderContent(formProps)}
      />
    );

  };

  const renderContent = ({
                           handleSubmit,
                           submitting,
                           values,
                           form,
                         }: FormRenderProps<ClinicConfDto>): React.ReactNode => {

    const clinicName = clinicInfo ? `(${clinicInfo.name})` : '';

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('clinic.config.title')} {clinicName}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ paddingBottom: '0' }}>
            <Grid.Column width={16}>
              <ClinicConfigTabComponent activeView={activeView} onTabChange={handleTabChange} />
            </Grid.Column>
          </Grid.Row>

          {ConfigView.GENERAL === activeView &&
            <>
              {generalErrorMessages.length > 0 &&
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className='error'>
                      <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                        {testOrRehabErrorMessages.map((err: string) => <div key={err}>{err}</div>)}
                      </StyledErrorMessage>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              }
              <Grid.Row>
                <Grid.Column width={5}>
                  <DataLabel>{t('clinicConf.invoicePackageType')}</DataLabel>
                  <Field
                    name='invoicePackageType'
                    component={Select}
                    options={invoicePackageTypes}
                    clearlable
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <DataLabel>{t('clinicConf.reportsLogo')}</DataLabel>
                  <Field name='image'
                         component={ImageDropZone}
                         imageView={values.image ? undefined : values.imageView}
                         accept='image/png'
                         removeImageView={() => {
                           form.change('imageView', undefined);
                           form.change('image', undefined);
                         }
                         }
                         getErrorCode={getFineInvalidTypeError}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={9}>
                  <DataLabel>{t(`clinicConf.hideNumbers`)}</DataLabel>
                  <Field
                    name='hideNumbers'
                    component={RadioGroup}
                    radioWith='25%'
                    radioDefinitions={hideNumbersDefinitions}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          }

          {renderViews()}

          <Grid.Row style={{ paddingBottom: '0' }}>
            <Grid.Column width={16} style={{ borderTop: '1px solid #d4d9e0', marginTop: '0.3rem' }}>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={16} style={{ paddingRight: '0' }}>

              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }

              {
                ConfigView.GENERAL === activeView &&
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting || !!successMsg}
                  onClick={noop}
                >
                  {t('button.save')}
                </CompositeButton>
              }

              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={() => history.push(prevPath!, { id: clinicId, clinicId: state.clinicId })}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderClinicContentForm()}
        </React.Fragment>
        : <LoaderComponent message={t('general.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default ClinicConfigView;