import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ExerciseConfExtendedSearchResponseDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/teresa-conf/exerciseconf/extended-search`;

export const getExtendedSearchExerciseConfs = (source: CancelTokenSource): Promise<ExerciseConfExtendedSearchResponseDto> => {
  return axios
  .get(`${apiUri}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExtendedSearchExerciseConfsForDoctor = (source: CancelTokenSource): Promise<ExerciseConfExtendedSearchResponseDto> => {
  return axios
  .get(`${apiUri}/for-doctor`, { cancelToken: source.token })
  .then(response => response.data);
};