import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import { ClinicInvoiceResponseDto, ClinicInvoiceSearchRequest } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/invoice`;

export const searchClinicInvoiceData = (
  request: Partial<ClinicInvoiceSearchRequest>,
  source: CancelTokenSource): Promise<ClinicInvoiceResponseDto> => {
  return axios
  .post(`${apiUri}`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchByClinicAdminForClinicInvoiceData = (
  request: Partial<ClinicInvoiceSearchRequest>,
  source: CancelTokenSource): Promise<ClinicInvoiceResponseDto> => {
  return axios
  .post(`${apiUri}/clinic-invoice`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const downloadClinicInvoiceExportPdf = (
  invoicePdfExternalId: number,
  source: CancelTokenSource): Promise<any> => {
  return axios
  .get(`${apiUri}-export/download`,
    { params: { invoicePdfExternalId }, cancelToken: source.token, responseType: 'blob' })
  .then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',
      response.headers['content-disposition'].split('filename=')[1]);
    document.body.appendChild(link);
    link.click();

    return true;
  });
};