import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import { MandateDto, MandateSearchRequest } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/mandate`;

export const searchMandates = (
  request: Partial<MandateSearchRequest>,
  source: CancelTokenSource): Promise<MandateDto[]> => {

  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};