import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const StyledTabs = styled.div`
    display: flex;

  .tab {
    cursor: pointer;
    padding: 5px 20px 10px;
    color: var(--dark-gray);
    margin-bottom: 30px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: 200ms all ease-in-out;
  }

  .tab.selected {
    border-bottom-color: var(--primary-color);
  }
`;

interface Props extends FieldRenderProps<any> {
}

const TabComponent = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { input } = props;

  const [activeIx, setActiveIx] = useState<number>(0);

  useEffect(() => {
    if (input.value) {
      setActiveIx(1);
    }
  }, []);

  const handleOnTabClick = (index: number) => {
    setActiveIx(index);

    let activeVal = undefined;
    if (index === 1) {
      activeVal = true;
    } else if (index === 2) {
      activeVal = false;
    }

    input.onChange(activeVal);
  }

  const tabLabels: string[] = ['showAll', 'active', 'inactive'];
  const tabs = tabLabels.map((tabName: string, index: number) => {
    return <div key={tabName}
                className={`tab ${activeIx === index && 'selected'}`}
                onClick={() => handleOnTabClick(index)}
    >
      {t(`tab.${tabName}`)}
    </div>
  });

  return (
    <StyledTabs>
      {tabs}
    </StyledTabs>
  );
};

export default TabComponent;
