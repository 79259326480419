import { useAuthContext } from 'auth/AuthContext';
import TsaGrid from 'components/TsaGrid';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, Icon, Input, InputOnChangeData, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { ExerciseConfDto, PatientExerciseDto } from 'ts-types/api.types';

const StyledIcon = styled(Icon)`
    cursor: pointer;
    border-radius: 4px;
`;

const StyledBtnContainer = styled.div`
  margin-bottom: 2rem;
  white-space: nowrap !important;
`;

const StyledButtons = styled.div`
  padding: 0 !important;
  margin: 0 0 0 4px !important;
  flex: 1 1 auto;
`;

const StyledSelectableTable = styled.div`
  flex: 50 50 auto;
  
  .header-row {
    align-items: baseline !important;

    .search-field {
      padding-right: 0 !important;
    }
  }
`;

const StyledTableRow = styled(Table.Row)`
  cursor: pointer;
`;

const ScrollableTableWrapper = styled.div`
  height: 375px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: none;
  border-radius: .28571429rem;
  flex: 50 50 auto;

  & .ui.table {
    border: none;
  }

   & .ui.table thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      z-index: 2;
      background: white;
  }

  & .ui.table tbody tr:last-child > td {
      border-bottom: 1px solid rgba(34,36,38,.15)
  }
`;

interface Props {
  selectedExerciseConfList?: Array<ExerciseConfDto>,
  availableExerciseConfList?: Array<ExerciseConfDto>,
  setData: (selectedExerciseConfs: Array<ExerciseConfDto>, availableExerciseConfs: Array<ExerciseConfDto>) => void
  setSelectedExerciseInTemplates: (markedExerciseConfs: ExerciseConfDto) => void,
  patientExercises?: PatientExerciseDto[]
}

const TemplateExerciseConfSegment = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();
  const isIphone = useIsIpadWidthOrBelow(430);

  const {
    selectedExerciseConfList = [],
    availableExerciseConfList = [],
    setData,
    setSelectedExerciseInTemplates,
    patientExercises
  } = props;

  const [selectedAddExerciseConfs, setSelectedAddExerciseConfs] = React.useState<ExerciseConfDto[]>([]);
  const [availableExerciseConfs, setAvailableExerciseConfs] = React.useState<ExerciseConfDto[]>([]);
  const [markedExerciseConf, setMarkedExerciseConf] = React.useState<ExerciseConfDto | undefined>(undefined);
  const [availableSearchQuery, setAvailableSearchQuery] =
    React.useState<string | undefined>(undefined);
  const [selectedSearchQuery, setSelectedSearchQuery] =
    React.useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedAddExerciseConfs(selectedExerciseConfList);
    setAvailableExerciseConfs(availableExerciseConfList);
  }, [selectedExerciseConfList, availableExerciseConfList]);

  const markExerciseConf = (exerciseConf: ExerciseConfDto) => {
    setMarkedExerciseConf(exerciseConf);
    setSelectedExerciseInTemplates(exerciseConf);
  };

  const changeOrderUp = () => {
    if (markedExerciseConf) {
      if (selectedAddExerciseConfs.includes(markedExerciseConf)) {

        const index = selectedAddExerciseConfs.indexOf(markedExerciseConf);
        if (index === 0) {return;}

        let newList: ExerciseConfDto[] = [...selectedAddExerciseConfs];

        const currentExercise = { ...markedExerciseConf };
        const previousExercise = { ...selectedAddExerciseConfs[index - 1] };

        newList[index].orderIndex = previousExercise.orderIndex;
        newList[index - 1].orderIndex = currentExercise.orderIndex;

        newList = _.orderBy(newList, ['orderIndex'], ['asc']);

        setSelectedAddExerciseConfs(newList);
        setData(newList, availableExerciseConfs);
      }
    }
  };

  const changeOrderDown = () => {
    if (markedExerciseConf) {
      if (selectedAddExerciseConfs.includes(markedExerciseConf)) {

        const index = selectedAddExerciseConfs.indexOf(markedExerciseConf);
        if (index + 1 === selectedAddExerciseConfs.length) {return;}

        let newList: ExerciseConfDto[] = selectedAddExerciseConfs;

        const currentExercise = { ...markedExerciseConf };
        const nextExercise = { ...selectedAddExerciseConfs[index + 1] };

        newList[index].orderIndex = nextExercise.orderIndex;
        newList[index + 1].orderIndex = currentExercise.orderIndex;

        newList = _.orderBy(newList, ['orderIndex'], ['asc']);

        setSelectedAddExerciseConfs(newList);
        setData(newList, availableExerciseConfs);
      }
    }
  };

  const addRemoveExerciseConf = () => {

    if (markedExerciseConf) {
      if (selectedAddExerciseConfs.includes(markedExerciseConf)) {

        const markedExerciseConfIndex = selectedAddExerciseConfs.indexOf(markedExerciseConf);

        let selected = selectedAddExerciseConfs.filter(t => markedExerciseConf !== t);
        if (markedExerciseConfIndex === 0) {
          selected.forEach(t => t.orderIndex = t.orderIndex - 1);
        } else if (markedExerciseConfIndex != (selectedAddExerciseConfs.length && 0)) {
          selected.forEach((t, index) => {
            if (index >= markedExerciseConfIndex) {
              t.orderIndex = t.orderIndex - 1
            }
          })
        }

        const available = [...availableExerciseConfs, markedExerciseConf];
        const sortedAvailableTestConfs = available.sort((tc1, tc2) => {
          const tc1Description = translateDescription(tc1).toLowerCase();
          const tc2Description = translateDescription(tc2).toLowerCase();

          return tc1Description > tc2Description ? 1 : tc1Description === tc2Description ? 0 : -1;
        })

        setSelectedAddExerciseConfs(selected);
        setAvailableExerciseConfs(sortedAvailableTestConfs);
        setMarkedExerciseConf(undefined);

        setData(selected, sortedAvailableTestConfs);

      } else if (availableExerciseConfs.includes(markedExerciseConf)) {

        let addedExerciseConf = { ...markedExerciseConf };
        addedExerciseConf.orderIndex = selectedAddExerciseConfs.length;

        const selected = [...selectedAddExerciseConfs, addedExerciseConf];
        const available = availableExerciseConfs.filter(t => markedExerciseConf !== t);

        setSelectedAddExerciseConfs(selected);
        setAvailableExerciseConfs(available);
        setMarkedExerciseConf(undefined);

        setData(selected, available);
      }
    }
  };

  const translateDescription = (exercise: ExerciseConfDto) => {
    const description = exercise.description;

    const testConfDescriptions: { [key: string]: string } = {
      'de': exercise.description || description,
      'en': exercise.descriptionEn || description,
      'fr': exercise.descriptionFr || description,
      'it': exercise.descriptionIt || description,
    };
    return testConfDescriptions[language];
  }

  const renderContent = (): React.ReactNode => {

    const selectable: Array<JSX.Element> = [];
    const available: Array<JSX.Element> = [];

    availableExerciseConfs.forEach(exercise => {

      const description = translateDescription(exercise);

      const availableSearchByQuery = availableSearchQuery
        && !_.isEmpty(availableSearchQuery)
        && description.toLowerCase().includes(availableSearchQuery.toLowerCase());

      if (availableSearchByQuery || !availableSearchQuery) {
        const active = markedExerciseConf === exercise;
        const availableExerciseConfDescription = (
          <StyledTableRow key={exercise.id} onClick={() => markExerciseConf(exercise)} active={active}>
            <Table.Cell>{description}</Table.Cell>
          </StyledTableRow>
        );

        available.push(availableExerciseConfDescription);
      }
    });

    if (selectedAddExerciseConfs && selectedAddExerciseConfs.length > 0) {
      selectedAddExerciseConfs.forEach(exercise => {
        const active = markedExerciseConf === exercise;
        const description = translateDescription(exercise);

        let completed = false;
        if (patientExercises) {
          const completedPatientExercises =
            patientExercises.filter(pe => pe.exerciseConfId === exercise.id && pe.completed);

          completed = completedPatientExercises.length > 0;
        }

        const selectedSearchByQuery = selectedSearchQuery
          && !_.isEmpty(selectedSearchQuery)
          && description.toLowerCase().includes(selectedSearchQuery.toLowerCase());
        if (selectedSearchByQuery || !selectedSearchQuery) {

          const selectableExerciseConfDescription = (
            <StyledTableRow key={exercise.id} onClick={() => markExerciseConf(exercise)} active={active}>
              {completed
                ? <Table.Cell>{`${description} (${t('patientTesting.completed')})`}</Table.Cell>
                : <Table.Cell>{description}</Table.Cell>
              }
            </StyledTableRow>
          );

          selectable.push(selectableExerciseConfDescription);
        }
      });
    }

    let exerciseCompleted = false;
    if (patientExercises) {
      const patientExercise =
        patientExercises.filter(pe => {
          return markedExerciseConf
            ? pe.exerciseConfId === markedExerciseConf.id && pe.completed
            : 0
        });
      exerciseCompleted = patientExercise.length > 0;
    }

    const isInSelected = markedExerciseConf ? selectedAddExerciseConfs.includes(markedExerciseConf) : false;
    const isInAvailable = markedExerciseConf ? availableExerciseConfs.includes(markedExerciseConf) : false;

    const selectedIndex = markedExerciseConf ? selectedAddExerciseConfs.indexOf(markedExerciseConf) : undefined;

    const disabledRight = !markedExerciseConf || isInAvailable || exerciseCompleted;
    const disabledLeft = !markedExerciseConf || isInSelected || exerciseCompleted;

    const disabledOrderUp = !markedExerciseConf || isInAvailable
      || (isInSelected && selectedIndex === 0);
    const disabledOrderDown = !markedExerciseConf || isInAvailable
      || (isInSelected && selectedIndex !== undefined && selectedIndex + 1 === selectedAddExerciseConfs.length);

    return (
      <React.Fragment>
        <Grid.Column width={7}>
          <StyledSelectableTable>
            {renderLeftTableSegment(t('exerciseTemplate.orderedExercises'), selectable)}
          </StyledSelectableTable>
        </Grid.Column>
        <Grid.Column width={isIphone ? 2 : 1} textAlign='center' verticalAlign='middle'>
          <StyledButtons>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledLeft}
                          onClick={addRemoveExerciseConf}
                          name='angle double left'
              />
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledRight}
                          onClick={addRemoveExerciseConf}
                          name='angle double right'
              />
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledOrderUp}
                          onClick={changeOrderUp}
                          name='angle up'
              />
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledOrderDown}
                          onClick={changeOrderDown}
                          name='angle down'
              />
            </StyledBtnContainer>
          </StyledButtons>
        </Grid.Column>
        <Grid.Column width={7}>
          <StyledSelectableTable>
            {renderRightTableSegment(t('exerciseTemplate.available'), available)}
          </StyledSelectableTable>
        </Grid.Column>
      </React.Fragment>
    );
  };

  const renderLeftTableSegment = (headerText: string, selectedExerciseConfs: Array<React.ReactNode>): React.ReactNode => {

    return (
      <React.Fragment>
        <TsaGrid>
          <Grid.Row className='header-row'>
            <Grid.Column width={6}>
              <Header as='h5'>
                {headerText}
              </Header>
            </Grid.Column>
            <Grid.Column width={10} className='search-field'>
              <Input
                placeholder={t('exerciseTemplate.searchSelectedExerciseConf.placeholder')}
                type='search'
                value={selectedSearchQuery}
                onChange={(evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                  setSelectedSearchQuery(data.value)
                }
                icon='search'
                iconPosition='left'
                autoFocus
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        <ScrollableTableWrapper>
          <Table celled selectable size='small'>
            <Table.Body>
              {selectedExerciseConfs}
            </Table.Body>
          </Table>
        </ScrollableTableWrapper>
      </React.Fragment>
    );
  };

  const renderRightTableSegment = (headerText: string, availableExerciseConfs: Array<React.ReactNode>): React.ReactNode => {

    return (
      <React.Fragment>
        <TsaGrid>
          <Grid.Row className='header-row'>
            <Grid.Column width={6}>
              <Header as='h5'>
                {headerText}
              </Header>
            </Grid.Column>
            <Grid.Column width={10} className='search-field'>
              <Input
                placeholder={t('exerciseTemplate.searchAvailableExerciseConf.placeholder')}
                type='search'
                value={availableSearchQuery}
                onChange={(evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                  setAvailableSearchQuery(data.value)
                }
                icon='search'
                iconPosition='left'
                autoFocus
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        <ScrollableTableWrapper>
          <Table celled selectable size='small'>
            <Table.Body>
              {availableExerciseConfs}
            </Table.Body>
          </Table>
        </ScrollableTableWrapper>
      </React.Fragment>
    );
  };

  return <>{renderContent()}</>;
};

export default TemplateExerciseConfSegment;