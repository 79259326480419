import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import AccordionWithArrow, { CustomContent, CustomTitle } from 'components/AccordionWithArrow';
import LoaderComponent from 'components/LoaderComponent';
import i18n from 'i18next';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ExerciseSearchSettings } from 'routes/exerciseextendedsearch/ExerciseConfExtendedSearchView';
import { Checkbox, Dropdown, DropdownItemProps, Input } from 'semantic-ui-react';
import {
  getExtendedSearchQuestionSets,
  getExtendedSearchQuestionSetsForDoctor,
} from 'service/questionSetExtendedSearchService';
import { getAllClinicCodesWithDescription } from 'service/testConfExtendedSearchService';
import styled from 'styled-components';
import { ScopeType } from 'ts-types/api.enums';
import {
  ClinicDto,
  PossibleAnswersDto,
  QuestionExtendedSearchDto,
  QuestionSetExtendedSearchDto,
  QuestionSetSearchRequest,
} from 'ts-types/api.types';

import { supportedLanguages } from 'util/localizationUtils';

const Label = styled.span`
    font-weight: bold;
    font-size: 15px;
`;

const FormBarLabel = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-right: 5px;
`;

const FormBarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    .ui.checkbox {
        border-radius: 4px;
        margin: -4px -4px;
`;

const ScrollableTsaGrid = styled.div`
  position: fixed;
  overflow: auto;
  top: 125px;
  bottom: 20px;
  width: calc(100% - 255px);
`;


export interface QuestionSetSearchSettings {
  searchValues: Partial<QuestionSetSearchRequest>;
  searchByPossibleAnswers: boolean;
  searchByQuestions: boolean;
  searchByQuestionSets: boolean;
  searchByAll: boolean;
  expandAll: boolean | undefined;
  expandQuestionSets: boolean | undefined;
  expandQuestions: boolean | undefined;
  expandPossibleAnswers: boolean | undefined;
  includeInactive: boolean;
  selectedClinics: Array<string>;
}

const QuestionSetExtendedSearchView = () => {
  const [questionSets, setQuestionSets] = useState<{ [index: string]: QuestionSetExtendedSearchDto }|undefined>(undefined);
  const [filteredQuestionSets, setFilteredQuestionSets] = useState<{
    [index: string]: QuestionSetExtendedSearchDto
  }>({});
  const [selectedClinics, setSelectedClinics] = useState<Array<string>>([]);
  const [clinics, setClinics] = useState<Array<ClinicDto>>([]);
  const [langOptions, setLangOptions] = useState<DropdownItemProps[]>([]);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(false);
  const [searchSettings, setSearchSettings] = useState<QuestionSetSearchSettings>({
    expandAll: false,
    expandPossibleAnswers: false,
    expandQuestionSets: false,
    expandQuestions: false,
    searchByAll: true,
    searchByPossibleAnswers: false,
    searchByQuestionSets: false,
    searchByQuestions: false,
    searchValues: { searchKey: '' },
    includeInactive: false,
    selectedClinics: [],
  });

  const currentLanguage = i18n.language;
  const cancelTokenSource = axios.CancelToken.source();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { updateLanguage } = useAuthContext();
  const { language } = useAuthContext();
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { currentUser } = useAuthContext();
  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);
  const scopeType = queryParams.get('scopeType') ? queryParams.get('scopeType') : location.state.scopeType;
  const clinicOptions = clinics.map((clinic) => ({
    key: clinic.clinicCode,
    value: clinic.clinicCode,
    text: `${clinic.clinicCode} - ${clinic.name}`,
  }));

  const renderLabel = (label: any) => ({
    content: label.value,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      searchQuestionSets();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchSettings.searchValues.searchKey, questionSets, formDataLoaded]);

  useEffect(() => {
    searchQuestionSets();
  }, [formDataLoaded]);

  useEffect(() => {
    if (questionSets) {
      setFormDataLoaded(true);
    }
  }, [questionSets]);

  useEffect(() => {
    setFormDataLoaded(false)
    isSystemAdmin && getAllClinicCodesWithDescription(cancelTokenSource).then(r => setClinics(r));
    const updatedLangOptions = supportedLanguages.map((lang) => ({
      key: lang,
      value: lang,
      text: t(`language.${lang}`),
    }));
    setLangOptions(updatedLangOptions);

    const scopeTypeAsEnum = (ScopeType.TEST == scopeType?.toUpperCase() ? ScopeType.TEST : ScopeType.REHAB);

    isSystemAdmin
      ? getExtendedSearchQuestionSets(scopeTypeAsEnum, cancelTokenSource).then(r => setQuestionSets(r.exerciseConfExtendedSearchDtoMap))
      : getExtendedSearchQuestionSetsForDoctor(scopeTypeAsEnum, cancelTokenSource).then(r => setQuestionSets(r.exerciseConfExtendedSearchDtoMap));
  }, [scopeType]);

  const handleIndeterminateOrFalseQuestions = (newSearchSettings: QuestionSetSearchSettings) => {
    if (newSearchSettings.expandQuestions == true) {newSearchSettings.expandQuestions = undefined;}
    return newSearchSettings.expandQuestions;
  };

  const handleIndeterminateOrFalsePossibleAnswers = (newSearchSettings: QuestionSetSearchSettings) => {
    if (newSearchSettings.expandPossibleAnswers == true) {newSearchSettings.expandPossibleAnswers = undefined;}
    return newSearchSettings.expandPossibleAnswers;
  };

  const updateSearchSettings = (key: keyof QuestionSetSearchSettings, value: any) => {
    const newSearchSettings = {
      ...searchSettings,
      [key]: value,
    };
    if (key != 'searchValues') {
      if (key == 'expandAll') {
        newSearchSettings.expandQuestionSets = value;
        newSearchSettings.expandQuestions = value;
        newSearchSettings.expandPossibleAnswers = value;
        newSearchSettings.expandAll = value;
      }
      if (key == 'expandQuestionSets'
        || key == 'expandQuestions'
        || key == 'expandPossibleAnswers'
        && value == false) {
        newSearchSettings.expandAll = undefined;
      }
      if (key == 'expandQuestionSets' && value == false) {
        newSearchSettings.expandQuestions = false;
        newSearchSettings.expandPossibleAnswers = false;
      }
      if (key == 'expandQuestions' && value == false) {
        newSearchSettings.expandPossibleAnswers = false;
      }
      if (key == 'expandQuestionSets' && value == undefined) {
        newSearchSettings.expandQuestions = handleIndeterminateOrFalseQuestions(newSearchSettings);
        newSearchSettings.expandPossibleAnswers = handleIndeterminateOrFalsePossibleAnswers(newSearchSettings);
      }
      if (key == 'expandQuestions' && value == undefined) {
        newSearchSettings.expandPossibleAnswers = handleIndeterminateOrFalsePossibleAnswers(newSearchSettings);
      }
      if ((key == 'searchByQuestionSets'
          || key == 'searchByQuestions'
          || key == 'searchByPossibleAnswers')
        && value == true) {
        newSearchSettings.searchByAll = false;
      }
      if (key == 'searchByAll') {
        newSearchSettings.searchByAll = value;
        newSearchSettings.searchByPossibleAnswers = value ? !value : value;
        newSearchSettings.searchByQuestions = value ? !value : value;
        newSearchSettings.searchByQuestionSets = !value;
      }
      if (!newSearchSettings.searchByQuestions
        && !newSearchSettings.searchByQuestionSets
        && !newSearchSettings.searchByPossibleAnswers) {
        newSearchSettings.searchByAll = true;
      }
    }
    setSearchSettings(newSearchSettings);
  };

  const handleShowInactive = (value?: boolean) => {
    const newIncludeInactive = value ? value : !searchSettings.includeInactive;
    (value == undefined) && updateSearchSettings('includeInactive', newIncludeInactive);
  };

  const handleExpandAll = () => {
    const newExpandAll = !searchSettings.expandAll;
    updateSearchSettings('expandAll', newExpandAll);

    const updatedQuestionSets: { [index: string]: QuestionSetExtendedSearchDto } = { ...filteredQuestionSets };
    Object.keys(updatedQuestionSets).forEach(key => {
      const questionSet = filteredQuestionSets[key];
      updatedQuestionSets[key] = {
        ...questionSet,
        expanded: newExpandAll,
        questionsExpanded: newExpandAll,
        questionsMap: Object.keys(questionSet.questionsMap).reduce((questionMap, questionKey) => {
          const question = questionSet.questionsMap[questionKey];
          questionMap[questionKey] = {
            ...question,
            expanded: newExpandAll,
            possibleAnswersExpanded: newExpandAll,
          };
          return questionMap;
        }, {} as { [index: string]: QuestionExtendedSearchDto }),
      };
    });

    setFilteredQuestionSets(updatedQuestionSets);
  };

  const handleExpandQuestionSets = () => {
    const newExpandQuestionSets = !searchSettings.expandQuestionSets;
    updateSearchSettings('expandQuestionSets', newExpandQuestionSets);

    const updatedQuestionSets = { ...filteredQuestionSets };
    Object.keys(updatedQuestionSets).forEach(key => {
      updatedQuestionSets[key] = {
        ...updatedQuestionSets[key],
        expanded: newExpandQuestionSets,
      };
    });

    setFilteredQuestionSets(updatedQuestionSets);
  };

  const handleExpandQuestions = () => {
    const newExpandQuestions = !searchSettings.expandQuestions;
    updateSearchSettings('expandQuestions', newExpandQuestions);

    const updatedQuestions = { ...filteredQuestionSets };
    Object.keys(updatedQuestions).forEach(key => {
      const questionSet = updatedQuestions[key];
      updatedQuestions[key] = {
        ...questionSet,
        questionsExpanded: newExpandQuestions,
        questionsMap: Object.keys(questionSet.questionsMap).reduce((questionMap, questionKey) => {
          const result = questionSet.questionsMap[questionKey];
          questionMap[questionKey] = {
            ...result,
            expanded: newExpandQuestions,
          };
          return questionMap;
        }, {} as { [index: string]: QuestionExtendedSearchDto }),
      };
    });

    setFilteredQuestionSets(updatedQuestions);
  };

  const handleExpandPossibleAnswers = () => {
    const newPossibleAnswersExpand = !searchSettings.expandPossibleAnswers;
    updateSearchSettings('expandPossibleAnswers', newPossibleAnswersExpand);

    const updatedQuestionSets = { ...filteredQuestionSets };
    Object.keys(updatedQuestionSets).forEach(key => {
      const questionSet = updatedQuestionSets[key];
      if (questionSet.questionsMap) {
        updatedQuestionSets[key] = {
          ...questionSet,
          questionsMap: Object.keys(questionSet.questionsMap).reduce((questionMap, questionKey) => {
            const result = questionSet.questionsMap[questionKey];
            if (result.expanded) {
              questionMap[questionKey] = {
                ...result,
                possibleAnswersExpanded: newPossibleAnswersExpand,
              };
            } else {
              questionMap[questionKey] = { ...result };
            }
            return questionMap;
          }, {} as { [index: string]: QuestionExtendedSearchDto }),
        };
      }
    });

    setFilteredQuestionSets(updatedQuestionSets);
  };

  const searchQuestionSets = useCallback(() => {
    const newQuestionSets = { ...questionSets };

    if (!searchSettings.searchValues.searchKey) {
      setFilteredQuestionSets({ ...questionSets });
      return;
    }
    if (!searchSettings.searchValues.searchKey) {
      const filtered = Object.keys(newQuestionSets).map((key) => {
        let testConf = newQuestionSets[key];
        if (filteredQuestionSets[key]) {
          testConf = filteredQuestionSets[key];
        }
        return testConf;
      });
      let res = _.keyBy(filtered, 'id');
      setFilteredQuestionSets(res);
      return;
    }

    const filtered = Object.keys(newQuestionSets).map((key) => {
      const questionSet = newQuestionSets[key];
      const { questionsMap } = questionSet;
      let questionSetMatches = false;
      let questionMatches = false;
      let possibleAnswerMatches = false;

      if (searchSettings.searchByAll || searchSettings.searchByQuestionSets) {
        if (getQuestionSetDescription(questionSet)
        .toLowerCase()
        .includes(searchSettings.searchValues.searchKey!.toLowerCase())) {
          questionSetMatches = true;
        }
      }

      if (searchSettings.searchByAll || searchSettings.searchByQuestions) {
        Object.keys(questionsMap).forEach(questionKey => {
          const question = questionsMap[questionKey];
          if (getQuestionDescription(question)
          .toLowerCase()
          .includes(searchSettings.searchValues.searchKey!.toLowerCase())) {
            questionMatches = true;
            questionsMap[questionKey] = { ...question, expanded: true };
          }
        });
      }

      if (searchSettings.searchByAll || searchSettings.searchByPossibleAnswers) {
        Object.keys(questionsMap).forEach(resultKey => {
          const question = questionsMap[resultKey];
          if (question.possibleAnswersMap && Object.values(question.possibleAnswersMap).some(possibleAnswer => {
            return getPossibleAnswerDescription(possibleAnswer)
            .toLowerCase()
            .includes(searchSettings.searchValues.searchKey!.toLowerCase());
          })) {
            possibleAnswerMatches = true;
            questionsMap[resultKey] = { ...question, possibleAnswersExpanded: true, expanded: true };
          }
        });
      }

      if (questionMatches || possibleAnswerMatches) {
        return {
          ...questionSet,
          expanded: true,
          questionsExpanded: true,
          questionsMap: { ...questionsMap },
        };
      } else if (questionSetMatches) {
        return { ...questionSet, expanded: true };
      }

      return null;
    }).filter(item => item !== null).reduce((acc, key) => {
      const a = key as QuestionSetExtendedSearchDto;
      acc[a!.id] = a;
      return acc;
    }, {} as { [index: string]: QuestionSetExtendedSearchDto });

    setFilteredQuestionSets(filtered);
  }, [
    searchSettings,
    language,
    questionSets,
  ]);

  const handleIndeterminateCheckBox = (flag: boolean, value: keyof QuestionSetSearchSettings) => {
    if (searchSettings.expandAll && flag) {
      updateSearchSettings('expandAll', undefined);
    }
    if (flag) {
      updateSearchSettings(value, undefined);
    }
  };

  const handleClinicDropdownChange = (value: any) => {
    if (Array.isArray(value)) {
      const selected = value as string[];
      setSelectedClinics(selected);
    }
  };

  const handleQuestionSetOnExpand = (id: string) => {
    setFilteredQuestionSets(prevQuestionSets => {
      const updatedQuestionSets = { ...prevQuestionSets };
      updatedQuestionSets[id] = {
        ...updatedQuestionSets[id],
        expanded: !updatedQuestionSets[id].expanded,
      };
      return updatedQuestionSets;
    });
    handleIndeterminateCheckBox(searchSettings.expandQuestionSets!, 'expandQuestionSets');
  };

  const handleQuestionSetQuestionsOnExpand = (id: string) => {
    setFilteredQuestionSets(prevQuestionSets => {
      const updatedQuestionSets = { ...prevQuestionSets };
      updatedQuestionSets[id] = {
        ...updatedQuestionSets[id],
        questionsExpanded: !updatedQuestionSets[id].questionsExpanded,
      };
      return updatedQuestionSets;
    });
    handleIndeterminateCheckBox(searchSettings.expandQuestions!, 'expandQuestions');
  };

  const handleQuestionOnExpand = (questionId: string, questionSetId: string) => {
    setFilteredQuestionSets(prevQuestionSets => {
      const updatedQuestionSets = { ...prevQuestionSets };
      const updatedQuestions = { ...updatedQuestionSets[questionSetId].questionsMap };
      updatedQuestions[questionId] = {
        ...updatedQuestions[questionId],
        expanded: !updatedQuestions[questionId].expanded,
      };
      updatedQuestionSets[questionSetId] = {
        ...updatedQuestionSets[questionSetId],
        questionsMap: { ...updatedQuestions },
      };
      return updatedQuestionSets;
    });
    handleIndeterminateCheckBox(searchSettings.expandQuestions!, 'expandQuestions');
  };

  const handlePossibleAnswersOnExpand = (questionId: string, questionSetIndex: string) => {
    setFilteredQuestionSets(prevQuestionSets => {
      const updatedQuestionSets = { ...prevQuestionSets };
      const updatedQuestions = { ...updatedQuestionSets[questionSetIndex].questionsMap };
      updatedQuestions[questionId] = {
        ...updatedQuestions[questionId],
        possibleAnswersExpanded: !updatedQuestions[questionId].possibleAnswersExpanded,
      };
      updatedQuestionSets[questionSetIndex] = {
        ...updatedQuestionSets[questionSetIndex],
        questionsMap: { ...updatedQuestions },
      };
      return updatedQuestionSets;
    });
    handleIndeterminateCheckBox(searchSettings.expandPossibleAnswers!, 'expandPossibleAnswers');
  };

  const highlightText = (fullText: string, searchTerm: string) => {
    if (!searchTerm) return fullText;
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    const parts = fullText.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part,
    );
  };

  const renderPossibleAnswersContent = useCallback((question: QuestionExtendedSearchDto) => {
    return Object.keys(question.possibleAnswersMap).map((possibleAnswerId: string) => {
      const possibleAnswer: PossibleAnswersDto = question.possibleAnswersMap[possibleAnswerId];
      return <div style={{ marginTop: '5px' }}>
        {(searchSettings.searchByPossibleAnswers || searchSettings.searchByAll)
          ? highlightText(
            getPossibleAnswerDescription(possibleAnswer),
            searchSettings.searchValues.searchKey ? searchSettings.searchValues.searchKey : '',
          )
          : getPossibleAnswerDescription(possibleAnswer)} {possibleAnswer.score !== null ? <>/ {possibleAnswer.score}</> : <>/
        ---</>}
      </div>;
    });
  }, [questionSets, searchSettings]);

  const renderQuestionsContent = useCallback((questionSet: QuestionSetExtendedSearchDto, questionSetId: string) => {
    return Object.keys(questionSet.questionsMap).map((questionId: string) => {
      const question: QuestionExtendedSearchDto = questionSet.questionsMap[questionId];
      const possibleAnswersMapLength = Object.keys(question.possibleAnswersMap).length;
      return <AccordionWithArrow expanded={question.expanded}
                                 onExpand={() => handleQuestionOnExpand(questionId, questionSetId)}>
        <CustomTitle>
          {(searchSettings.searchByQuestions || searchSettings.searchByAll)
            ? highlightText(getQuestionDescription(question), searchSettings.searchValues.searchKey || '')
            : getQuestionDescription(question)}
          <> / {question.questionType}</>
          {question.questionType === 'NUMBER' && question.minimumValue !== null && question.maximumValue !== null && (
            <> / {question.minimumValue} - {question.maximumValue}</>
          )}
          {question.color && (
            <> / <span style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              backgroundColor: question.color,
              marginLeft: '5px',
            }}></span></>
          )}
        </CustomTitle>

        <CustomContent>
          {
            possibleAnswersMapLength > 0 &&
            <AccordionWithArrow
              expanded={question.possibleAnswersExpanded}
              onExpand={() => handlePossibleAnswersOnExpand(questionId, questionSetId)}
            >
              <CustomTitle>
                <Label>{t('header.possibleAnswers')} ({possibleAnswersMapLength}): </Label>
              </CustomTitle>
              <CustomContent>
                {renderPossibleAnswersContent(question)}
              </CustomContent>
            </AccordionWithArrow>
          }
        </CustomContent>
      </AccordionWithArrow>;
    });
  }, [questionSets, searchSettings]);

  const renderQuestionSetRows = useCallback(() => {
    const filteredSets = Object.keys(filteredQuestionSets).filter((questionSetId) => {
      const questionSet = filteredQuestionSets[questionSetId];
      return (searchSettings.includeInactive || questionSet.active) &&
        (selectedClinics.length < 1 || questionSet.clinics.some(clinic => selectedClinics.includes(clinic)));
    });

    return (
      <ScrollableTsaGrid>
        {filteredSets.map((questionSetId) => {
          const questionSet = filteredQuestionSets[questionSetId];
          const questionsMapLength = Object.keys(questionSet.questionsMap).length;

          return (
            <AccordionWithArrow
              key={questionSetId}
              expanded={questionSet.expanded}
              onExpand={() => handleQuestionSetOnExpand(questionSetId)}
            >
              <CustomTitle>
                {(searchSettings.searchByQuestionSets || searchSettings.searchByAll)
                  ? highlightText(
                    getQuestionSetDescription(questionSet),
                    searchSettings.searchValues.searchKey ? searchSettings.searchValues.searchKey : '',
                  )
                  : getQuestionSetDescription(questionSet)}
                {questionSet.type != null ? <> / {t(`questionSetType.${questionSet.type}`)}</> : <> / ---</>}
              </CustomTitle>
              <CustomContent>
                {questionsMapLength > 0 && (
                  <AccordionWithArrow
                    expanded={questionSet.questionsExpanded}
                    onExpand={() => handleQuestionSetQuestionsOnExpand(questionSetId)}
                  >
                    <CustomTitle>
                      <Label>{t('header.questions')} ({questionsMapLength}): </Label>
                    </CustomTitle>
                    <CustomContent>
                      {renderQuestionsContent(questionSet, questionSetId)}
                    </CustomContent>
                  </AccordionWithArrow>
                )}
              </CustomContent>
            </AccordionWithArrow>
          );
        })}
      </ScrollableTsaGrid>
    );
  }, [
    questionSets,
    filteredQuestionSets,
    selectedClinics,
    searchSettings,
  ]);

  const renderFormBar = () => {
    const expandAll = searchSettings.expandAll;
    const expandQuestionSets = searchSettings.expandQuestionSets;
    const scopeTypeAsEnum = (ScopeType.TEST == scopeType?.toUpperCase() ? ScopeType.TEST : ScopeType.REHAB);
    return (
      <div className='search-form' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className='title-h1' style={{ width: "350px", paddingLeft: '0.6rem' }}>
          {scopeTypeAsEnum == ScopeType.TEST ? t('sidebar.subitem.testQuestionSets') : t('sidebar.subitem.exerciseQuestionSets')}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {isSystemAdmin && <FormBarContainer>
              <Checkbox
                type='checkbox'
                id='includeInactive'
                checked={searchSettings.includeInactive}
                onChange={() => handleShowInactive()}
              />
              <label htmlFor='includeInactive'>{t('button.includeInactive')}</label>
            </FormBarContainer>}
            <FormBarLabel>
              <div>{t('testconf.expand')}:</div>
            </FormBarLabel>
            <FormBarContainer>
              <Checkbox
                indeterminate={expandAll == undefined}
                id='expandAll'
                checked={searchSettings.expandAll}
                onChange={handleExpandAll}
              />
              <label htmlFor='expandAll'>{t('sex.ALL')}</label>
            </FormBarContainer>
            <FormBarContainer>
              <Checkbox
                indeterminate={expandQuestionSets == undefined}
                id='expandQuestionSets'
                checked={searchSettings.expandQuestionSets}
                onChange={handleExpandQuestionSets}
              />
              <label htmlFor='expandQuestionSets'>{t('clinic.config.questionSets')}</label>
            </FormBarContainer>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandQuestions == undefined}
                id='expandQuestions'
                checked={searchSettings.expandQuestions}
                onChange={handleExpandQuestions}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandQuestions'
              >
                {t('header.questions')}
              </label>
            </FormBarContainer>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandPossibleAnswers == undefined}
                id='expandPossibleAnswers'
                checked={searchSettings.expandPossibleAnswers}
                onChange={handleExpandPossibleAnswers}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandPossibleAnswers'
              >
                {t('header.possibleAnswers')}
              </label>
            </FormBarContainer>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormBarContainer>
              <FormBarLabel>{t('button.search')}:</FormBarLabel>
            </FormBarContainer>
            <FormBarContainer>
              <Checkbox
                id='searchByAll'
                checked={searchSettings.searchByAll}
                onChange={() => {
                  updateSearchSettings('searchByQuestionSets', false);
                  updateSearchSettings('searchByQuestions', false);
                  updateSearchSettings('searchByAll', !searchSettings.searchByAll);
                }}
              />
              <label htmlFor='searchByAll'>{t('sex.ALL')}</label>
            </FormBarContainer>
            <FormBarContainer>
              <Checkbox
                id='searchByQuestionSets'
                checked={searchSettings.searchByQuestionSets}
                onChange={() => {
                  if (!searchSettings.searchByQuestions
                    && searchSettings.searchByQuestionSets
                    && !searchSettings.searchByPossibleAnswers) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByQuestionSets', !searchSettings.searchByQuestionSets);
                }}
              />
              <label htmlFor='searchByQuestionSets'>{t('clinic.config.questionSets')}</label>
            </FormBarContainer>
            <FormBarContainer>
              <Checkbox
                id='searchByQuestions'
                checked={searchSettings.searchByQuestions}
                onChange={() => {
                  if (searchSettings.searchByQuestions
                    && !searchSettings.searchByQuestionSets
                    && !searchSettings.searchByPossibleAnswers) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByQuestions', !searchSettings.searchByQuestions);
                }}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='searchByQuestions'
              >
                {t('header.questions')}
              </label>
            </FormBarContainer>
            <FormBarContainer>
              <Checkbox
                id='searchByPossibleAnswers'
                checked={searchSettings.searchByPossibleAnswers}
                onChange={() => {
                  if (!searchSettings.searchByQuestionSets
                    && !searchSettings.searchByQuestions
                    && searchSettings.searchByPossibleAnswers) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByPossibleAnswers', !searchSettings.searchByPossibleAnswers);
                }}
              />
              <label htmlFor='searchByPossibleAnswers'>{t('header.possibleAnswers')}</label>
            </FormBarContainer>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {isSystemAdmin && <FormBarContainer>
              <Dropdown
                style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', width: '300px' }}
                placeholder={t('clinic.selectClinics')}
                fluid
                multiple
                search
                selection
                options={clinicOptions}
                value={selectedClinics}
                onChange={(e, { value }) => handleClinicDropdownChange(value)}
                renderLabel={renderLabel}
              />
            </FormBarContainer>}
            <Input
              style={{ marginLeft: '10px' }}
              type='text'
              placeholder={t('button.search')}
              value={searchSettings.searchValues.searchKey}
              onChange={(e) => updateSearchSettings('searchValues', {
                ...searchSettings.searchValues,
                searchKey: e.target.value,
              })}
            />
            <Dropdown
              style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}
              className='language-menu-item'
              text={t(`language.${currentLanguage}`)}
              direction='left'
              icon='chevron up'
              value={currentLanguage}
              options={langOptions}
              onChange={(evt, data) => {
                const value = data.value as string;
                updateLanguage(value);
              }}
              selectOnBlur
              selectOnNavigation={false}
              fluid
            />
          </div>
        </div>
      </div>
    );
  };

  const getQuestionSetDescription = (questionSet: QuestionSetExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': questionSet.name || '',
      'en': questionSet.nameEn || questionSet.name || '',
      'fr': questionSet.nameFr || questionSet.name || '',
      'it': questionSet.nameIt || questionSet.name || '',
    };
    return texts[language];
  };

  const getQuestionDescription = (question: QuestionExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': question.text || '',
      'en': question.textEn || question.text || '',
      'fr': question.textFr || question.text || '',
      'it': question.textIt || question.text || '',
    };
    return texts[language];
  };

  const getPossibleAnswerDescription = (possibleAnswer: PossibleAnswersDto) => {
    const texts: { [key: string]: string } = {
      'de': possibleAnswer.description || '',
      'en': possibleAnswer.descriptionEn || possibleAnswer.description || '',
      'fr': possibleAnswer.descriptionFr || possibleAnswer.description || '',
      'it': possibleAnswer.descriptionIt || possibleAnswer.description || '',
    };
    return texts[language];
  };

  return (
    <div>
      {renderFormBar()}
      {formDataLoaded
        ? <React.Fragment>
          {renderQuestionSetRows()}
        </React.Fragment>
        : <LoaderComponent message={t('general.loading')} />
      }
    </div>
  );
};

export default QuestionSetExtendedSearchView;