import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import DatePicker from 'components/final-form/DatePicker';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import { noop } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Loader } from 'semantic-ui-react';
import { getClinicConf } from 'service/clinicConfService';
import {
  downloadClinicInvoiceExportPdf,
  searchByClinicAdminForClinicInvoiceData,
  searchClinicInvoiceData,
} from 'service/clinicInvoiceService';
import styled from 'styled-components';
import {
  ClinicInvoiceDataSearchRequest,
  ClinicInvoiceDto,
  ClinicInvoiceSearchRequest,
  ClinicInvoiceTotalsDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { emptyTableCell } from 'util/tableUtils';

const ClinicInvoiceDataContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ClinicInvoiceDataItem = styled(DataLabel).attrs((props) => ({}))`
    padding: 0.5em 1.1em 0 1.1em;
    text-align: center;
    font-weight: ${(props) => props.bold ? 600 : 'normal'};
`;


interface Props {
}

const ClinicInvoiceView = (props: Props) => {

  const cancelTokenSource = axios.CancelToken.source();
  const { t } = useTranslation('teresa');

  const history = useHistory();
  const { state } = useLocation();
  const { clinicInfo, setClinicId } = useClinicInfoContext();
  const clinicId: number | undefined = state?.clinicId ? state?.clinicId : undefined;
  const clinicCode: string | undefined = state?.clinicCode ? state?.clinicCode : undefined;

  const { currentUser } = useAuthContext();
  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const [invoiceConfigPackage, setInvoiceConfigPackage] = useState<String>();
  const [clinicInvoiceTotals, setClinicInvoiceTotals] =
    useState<ClinicInvoiceTotalsDto | null>(null);

  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;

  const [foundClinicInvoices, setFoundClinicInvoices] = useState<ClinicInvoiceDto[]>([]);
  const [clinicInvoicesLoaded, setClinicInvoicesLoaded] = useState<boolean>(true);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [searchValues, setSearchValues] = useState<ClinicInvoiceSearchRequest>({
    clinicCode: clinicCode!,
    date: moment().format('YYYY-MM-DD'),
  });

  React.useEffect(() => {
    setClinicId(state?.clinicId ? state?.clinicId : undefined)
    fetchClinicInvoice(searchValues);
  }, []);

  const handleError = (error: any, searchValues?: ClinicInvoiceDataSearchRequest) => {
    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.clinicInvoiceNotFound,
        errorUtils.userNotAdmin,
        errorUtils.invoiceConfigNotFoundForClinic,
        errorUtils.invoiceConfigNotFound,
        errorUtils.invalidInput,
        errorUtils.numOfSetsCantBeNull,
      ];

      const violations: Array<any> = error.violations;
      const errorMessages: Array<string> = [];

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (violation.errorCode === errorUtils.invoiceConfigNotFoundForClinic && searchValues) {
            errorMessages.push(
              t(
                `error.${violation.errorCode}`,
                {
                  invoiceConfigType: t(`invoiceConfigType.${searchValues.invoiceConfigType}`),
                  date: moment(searchValues.date).format('DD.MM.YYYY'),
                },
              ),
            );
          } else {
            errorMessages.push(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          errorMessages.push(t(`error.${errorCode}`));
        } else {
          errorMessages.push(t('error.general'));
        }
      }

    }
  };

  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [errorMessage];
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSearch = (values: ClinicInvoiceSearchRequest) => {
    setSearchValues(values);
    fetchClinicInvoice(values);
  };

  const fetchClinicInvoice = (values: ClinicInvoiceSearchRequest): void => {

    setErrorMessage();

    const onFinally = () => {
      setClinicInvoicesLoaded(true);
    };

    setClinicInvoicesLoaded(false);
    setFoundClinicInvoices([]);
    setInvoiceConfigPackage(undefined);

    getClinicConf(clinicId!, cancelTokenSource)
    .then(response => {
      setInvoiceConfigPackage(response.invoicePackageType);
    })
    .catch((e: any) => handleError(e.response.data));

    if (isSystemAdmin) {
      searchClinicInvoiceData(values, cancelTokenSource)
      .then(response => {
        setFoundClinicInvoices(response.clinicInvoiceDtos);
        setClinicInvoiceTotals(response.clinicInvoiceTotals);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(onFinally);
    } else {
      searchByClinicAdminForClinicInvoiceData(values, cancelTokenSource)
      .then(response => {
        setFoundClinicInvoices(response.clinicInvoiceDtos);
        setClinicInvoiceTotals(response.clinicInvoiceTotals);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(onFinally);
    }
  };

  const clinicInvoiceStatusCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`invoiceStatus.${cellData}`);
    }
    return emptyTableCell();
  };

  const testPriceCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <div style={{ textAlign: 'right', paddingRight: '2.3rem' }}>{cellData}</div>;
    }
    return emptyTableCell();
  };

  const invoiceAmountCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <div style={{ textAlign: 'right' }}>{cellData}</div>;
    }
    return emptyTableCell();
  };

  const invoiceDateCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY');
    }
    return emptyTableCell();
  };

  const invoiceConfigTypeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`invoiceConfigType.${cellData}`);
    }
    return emptyTableCell();
  };

  const invoiceTypeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`invoiceType.${cellData}`);
    }
    return emptyTableCell();
  };


  const paymentDateCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY');
    }
    return emptyTableCell();
  };

  const performDownloadClinicInvoice = async (invoicePdfExternalId: number) => {
    if (invoicePdfExternalId) {
      try {
        await downloadClinicInvoiceExportPdf(invoicePdfExternalId, cancelTokenSource);
      } catch (e) {
        handleError(e.response.data);
      }
    }
  };

  const actionCellRenderer = ({ cellData, rowData }: any) => {
    return <>
      {(isSystemAdmin) &&
        <InnerTableActionButton
          message={t('button.download')}
          disabled={(rowData.invoicePdfExternalId == null)}
          onConfirm={() => performDownloadClinicInvoice(rowData.invoicePdfExternalId)}
        />
      }
    </>;
  };

  const renderClinicInvoiceTable = (): JSX.Element => {

    const columns = [
      {
        width: 100,
        label: t('clinicInvoice.id'),
        dataKey: 'id',
      },
      {
        width: 200,
        flexGrow: 0,
        label: t('clinicInvoice.invoiceDate'),
        dataKey: 'invoiceDate',
        cellRenderer: (invoiceDateCellRenderer),
      },
      {
        width: 150,
        flexGrow: 1,
        label: t('clinicInvoice.invoiceConfigType'),
        dataKey: 'invoiceConfigType',
        cellRenderer: (invoiceConfigTypeCellRenderer),
      },
      {
        width: 150,
        flexGrow: 1,
        label: t('clinicInvoice.invoiceType'),
        dataKey: 'invoiceType',
        cellRenderer: (invoiceTypeCellRenderer),
      },
      {
        width: 100,
        flexGrow: 1,
        label: (t('clinicInvoice.numOfSets')),
        dataKey: 'numOfSets',
      },
      {
        width: 100,
        label: t('clinicInvoice.testPrice'),
        dataKey: 'testPrice',
        cellRenderer: (testPriceCellRenderer),
      },
      {
        width: 100,
        label: t('clinicInvoice.invoiceAmount'),
        dataKey: 'invoiceAmount',
        cellRenderer: (invoiceAmountCellRenderer),
      },
      {
        width: 200,
        flexGrow: 1,
        label: t('clinicInvoice.paymentDate'),
        dataKey: 'paymentDate',
        cellRenderer: (paymentDateCellRenderer),
      },
      {
        width: 150,
        flexGrow: 1,
        label: t('clinicInvoice.invoiceStatus'),
        dataKey: 'invoiceStatus',
        cellRenderer: (clinicInvoiceStatusCellRenderer),
      },
      {
        width: 250,
        flexGrow: 0,
        dataKey: 'id',
        label: t('domain.actions'),
        cellRenderer: (actionCellRenderer),
      },
    ];

    return (
      <VirtualizedTable
        rowCount={foundClinicInvoices.length}
        rowGetter={clinicInvoiceRowGetter}
        rowRenderer={clinicInvoiceRowRenderer}
        columns={columns}
      />
    );
  };

  const renderSearchClinicInvoiceForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={(values: ClinicInvoiceSearchRequest) => handleSearch(values)}
        initialValues={searchValues}
        subscription={{ pristine: true, submitting: true }}
        // create new invoice request when adding new invoice
        render={(formProps: FormRenderProps<any>) => renderSearchFormContent(formProps)}
      />
    );
  };

  const renderSearchFormContent = ({
                                     handleSubmit,
                                     submitting,
                                   }: FormRenderProps<any>): React.ReactNode => {

    const clinicName = clinicInfo ? `(${clinicInfo.name})` : '';

    // @ts-ignore
    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('clinicInvoice.header')} {clinicName}</div>
        </div>

        <form onSubmit={handleSubmit}>
          <TsaGrid stackable columns={3}>
            {
              errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='error'>
                    <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                      {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </div>
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={3} verticalAlign='bottom'>
                <DataLabel>{t('clinicInvoice.date')}</DataLabel>
                <Field
                  name='date'
                  component={DatePicker}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={2} verticalAlign='bottom'>
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting}
                  onClick={noop}
                >
                  {t('button.search')}
                </CompositeButton>
              </Grid.Column>
              {invoiceConfigPackage
                ? <Grid.Column width={11}>
                  <ClinicInvoiceDataContainer>
                    <ClinicInvoiceDataItem>
                      {t(`clinicInvoice.packageType`)}
                      <div style={{ fontWeight: 'bold' }}>
                        {t(`invoicePackageType.${invoiceConfigPackage}`)}
                      </div>
                    </ClinicInvoiceDataItem>
                    {clinicInvoiceTotals &&
                      <>
                        <ClinicInvoiceDataItem>
                          {t(`clinicDetails.validFromDate`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {moment(clinicInvoiceTotals.validFrom).format('DD.MM.YYYY')}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.validUntil`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {moment(clinicInvoiceTotals.validUntil).format('DD.MM.YYYY')}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.totalSetsInvoiced`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {clinicInvoiceTotals.totalSetsInvoiced}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.totalSetsMade`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {clinicInvoiceTotals.totalSetsMade}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.openSetsForInvoice`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {
                              clinicInvoiceTotals.openSetsForInvoice > 0
                                ? clinicInvoiceTotals.openSetsForInvoice
                                : 0
                            }
                          </div>
                        </ClinicInvoiceDataItem>
                      </>
                    }
                  </ClinicInvoiceDataContainer>
                </Grid.Column>
                : clinicInvoicesLoaded &&
                <Grid.Column width={4} verticalAlign='bottom'>
                  <DataLabel style={{ marginBottom: 0 }}>
                    <StyledErrorMessage color='yellow'>
                      {t('clinicInvoice.warning')}
                    </StyledErrorMessage>
                  </DataLabel>
                </Grid.Column>
              }
            </Grid.Row>
          </TsaGrid>
        </form>
      </>
    );
  };

  const clinicInvoiceRowGetter = ({ index }: any) => {

    Object.assign(foundClinicInvoices[index], { index: index + 1 });

    return foundClinicInvoices[index];
  };

  const clinicInvoiceRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };
  const goBack = () => {
    history.push(prevPath ? prevPath : '/',{
      id: clinicId,
      clinicId: state.clinicId
    });
  }

  const renderFormsAndTable = () => {
    return (
      <>
        {renderSearchClinicInvoiceForm()}
        <div className='data-table'>
          {clinicInvoicesLoaded && renderClinicInvoiceTable()}
          {!clinicInvoicesLoaded &&
            <Loader className='table-loader' active inline content={t('clinicInvoice.loading')} />}
        </div>

        <div
          style={{ width: '100%', height: '1px', backgroundColor: 'var(--very-light-blue)', marginTop: '1rem', marginBottom: '1rem' }}>
        </div>

        <div style={{ width: '100%', marginBottom: '1rem' }}>
          <CompositeButton
            type='button'
            className='action-button'
            secondary
            onClick={() => goBack()}
            floated='right'
          >
            {t('action.back')}
          </CompositeButton>
        </div>
      </>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderFormsAndTable()}
    </SearchDisplayContainer>
  );
};

export default ClinicInvoiceView;